import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { Pagination } from "@mui/material";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCreatorEditTableRequest, getCreatorViewTableRequest, getWorkflowCombineAction, getWorkflowTableAction } from "../../store/actions";
import Loader from "../Loader/Loader";


const WorkFlowRequest = (props) => {
  document.title = "EVT-WorkFlow Request";

  const { loading, workflowTableData,creatorEditChangeTableData, creatorViewChangeTableData,workflowCombineData } = props;
  const history = useNavigate();
  const [totalCount, setTotalCount] = useState<any>("");
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [expandedRows, setExpandedRows] = useState<number[]>([]); // Store expanded row indices
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState<any>("");
  const [selectedRequestIds, setSelectedRequestIds] = React.useState<number[]>(
    []
  );
  const [sort, setSort] = useState("asc");
  const [sortBy, setSortBy] = useState("");
  const [isCombinable, setIsCombinable]=useState(false);
  const recordsPerPage = 10;
  const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);




  useEffect(() => {
    props.getWorkflowTableAction({ currentPage, search, sort, sortBy });
  }, [currentPage, sort, search, sortBy]);

  useEffect(() => {
    if (workflowTableData) {
      const data = workflowTableData;
      setData(data.dataRows);
      setTotalCount(data?.totalRowsCount);
      setIsCombinable(data.isCombainable);
      
    }
  }, [workflowTableData]);

  const onClickPagination = (event, value) => {
    setCurrentPage(value);
  };

  // Function to toggle row expansion
  const toggleRow = (index: number) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((row) => row !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const onSortChange = (keys: any) => {
    const newSortOrder = sort === "asc" ? "desc" : "asc";
    setSort(newSortOrder);
    setSortBy(keys);
  };

  const checkboxOnchange = (event: any, requestID: number) => {
    if (event.target.checked) {
      setSelectedRequestIds((prev) => [...prev, requestID]);
    } else {
      setSelectedRequestIds((prev) => prev.filter((id) => id !== requestID));
    }
  };

  const [goToview, setGoToview] = useState(false);

  const handleView = (vechicleid, requestId) => {
    props.getCreatorViewTableRequest({ VehicleGroupId: vechicleid, RequestId: requestId,IsWorkflowRequest:true });
    setGoToview(true);
  };

  useEffect(() => { 

    if (Object.keys(creatorViewChangeTableData).length !== 0 && goToview) {
      setGoToview(false)      
      history(`/view-changes`);
    }
    
  }, [creatorViewChangeTableData]);

  const [refreshPage, setRefreshPage] = useState(false);
  
  const handleCombineRequest = () => {
    console.log(selectedRequestIds, "selectedRequestIds");
    props.getWorkflowCombineAction(selectedRequestIds);
    setRefreshPage(true);
  };

  useEffect(() => { 
    console.log(workflowCombineData, "workflowCombineData");
    if (workflowCombineData?.payload?.httpStatusCode === 200 && refreshPage) {      
      setRefreshPage(false);
      props.getWorkflowTableAction({ currentPage, search, sort, sortBy });
      
    }
    
  }, [workflowCombineData]);


  const [goToEdit, setGoToEdit] = useState(false);

  const handleEdit = (vechicleid, requestId) => {
    props.getCreatorEditTableRequest({
      VehicleGroupId: vechicleid,
      RequestId: requestId,
    });
    setGoToEdit(true);
  };

  useEffect(() => {
    // console.log(creatorEditChangeTableData, "creatorEditChangeTableData");

    if (Object.keys(creatorEditChangeTableData).length !== 0 && goToEdit) {
      setGoToEdit(false);
      history(`/edit-code-list`);
    }
  }, [creatorEditChangeTableData]);


  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="WorkFlow Request" pageTitle="Code List" />
          <Row>
            <Col xl={12}>
              <Card>
                <CardHeader className="align-items-center d-flex card-bg">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Approval Requests
                  </h4>
                  <div className="d-flex justify-content-sm-end mx-4">
                    <div className="search-box ms-2">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                  <div className="flex-shrink-0">
                    <div className="form-check form-switch form-switch-right form-switch-md">
                      <div className="input-group gap-3 justify-content-md-end ">
                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                          <Button
                            className="btn btn-primary btn-custom-blue"
                            type="button"
                            color="light"
                            style={
                              selectedRequestIds.length <= 1
                                ? { display: "none" }
                                : {}
                            }
                            onClick={() => handleCombineRequest()}
                          >
                            Combine Requests
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="card-padding">
                  <div className="live-preview">
                    <div
                      className="table-responsive table-card"
                      // style={{ maxHeight: "650px" }}
                    >
                      <table className="table align-middle table-bordered  table-striped mb-0">
                        <thead
                          className="table-light"
                          style={{ position: "sticky", top: "0", zIndex: "1" }}
                        >
                          <tr>
                            {isCombinable ?
                            (<th></th>):null
                            }

                            <th></th>
                            <th>
                              Request By
                              <i
                                onClick={() => onSortChange("requestedBy")}
                                className=" bx bx-sort me-3"
                                style={{ color: "#182b45", cursor: "pointer" }}
                              ></i>
                            </th>
                            <th>
                              Requested On{" "}
                              <i
                                onClick={() => onSortChange("requestedOn")}
                                className=" bx bx-sort me-3"
                                style={{ color: "#182b45", cursor: "pointer" }}
                              ></i>
                            </th>
                            <th>
                              Request Version{" "}
                              <i
                                onClick={() => onSortChange("requestedVersion")}
                                className=" bx bx-sort me-3"
                                style={{ color: "#182b45", cursor: "pointer" }}
                              ></i>
                            </th>
                            {/* <th>Current Version</th> */}
                            <th>
                              Vehical Group{" "}
                              <i
                                onClick={() => onSortChange("vehicleGroupName")}
                                className=" bx bx-sort me-3"
                                style={{ color: "#182b45", cursor: "pointer" }}
                              ></i>
                            </th>
                            <th>
                              Current Status{" "}
                              <i
                                onClick={() => onSortChange("status")}
                                className=" bx bx-sort me-3"
                                style={{ color: "#182b45", cursor: "pointer" }}
                              ></i>
                            </th>
                            <th>
                              Last Approval{" "}
                              <i
                                onClick={() => onSortChange("ApprovedOn")}
                                className=" bx bx-sort me-3"
                                style={{ color: "#182b45", cursor: "pointer" }}
                              ></i>
                            </th>

                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data?.map((item: any, index: any) => (
                            <React.Fragment key={index}>
                              {/* Main Row */}
                              <tr>
                                {isCombinable &&<td>
                                  {item.isCombainable? (
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      onChange={(event) => {
                                        checkboxOnchange(event, item.requestId);
                                      }}
                                      checked={selectedRequestIds.includes(
                                        item.requestId
                                      )}
                                    />
                                  ) : null}
                                </td> }
                                
                                <td>
                                  <Button
                                    className="btn btn-primary btn-custom-blue"
                                    type="button"
                                    color="light"
                                    onClick={() => toggleRow(index)}
                                    style={
                                      { ["--vz-btn-padding-y"]: "6px" } as any
                                    }
                                  >
                                    {expandedRows.includes(index) ? "-" : "+"}
                                  </Button>
                                </td>
                                <td>{item.requestedBy}</td>
                                <td>{item.requestedOn}</td>
                                <td>{item.requestedVersion}</td>
                                {/* <td>{item.CurrentVersion}</td> */}
                                <td>{item.vehicleGroupName}</td>
                                <td>{item.status}</td>
                                <td>{item.ApprovedOn}</td>

                                <td>
                                  <div className="d-flex gap-3">
                                    <button
                                      className="btn btn-md btn-soft-warning remove-list"
                                      title="View Changes In WorkFlow Request"
                                      data-bs-toggle="tooltip"
                                      data-bs-placement="top"
                                      onClick={() =>
                                        handleView(
                                          item.vehicleGroupId,
                                          item.requestId
                                        )
                                      }
                                    >
                                      <i className="ri-eye-fill align-bottom" />
                                    </button>
                                    {item.isEditable && (
                                       <button
                                          className="btn btn-md btn-soft-info edit-list"
                                          title="Edit Code List"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          // disabled={["Pending", "Rejected", "Approved"].includes(item.status)}
                                          // disabled={item.isEditable === false}
                                          onClick={() =>
                                            handleEdit(
                                              item.vehicleGroupId,
                                              item.requestId
                                            )
                                          }
                                        >
                                          <i className="ri-pencil-fill align-bottom" />
                                        </button>
                                    )}
                                   
                                    {/* <Button
                                      className="btn btn-primary btn-custom-blue"
                                      type="button"
                                      color="light"
                                    >
                                      View Status
                                    </Button> */}
                                  </div>
                                </td>
                              </tr>

                              {/* Expanded Row */}
                              {expandedRows.includes(index) && (
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td colSpan={8}>
                                    {/* Nested Table for CountryVehical */}
                                    <table className="table align-middle table-bordered table-nowrap table-striped mb-0">
                                      <thead className="table-light">
                                        <tr>
                                          <th>Country Group</th>
                                          <th>Vehicle</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item?.countryVehicleList?.map(
                                          (Vehicle, i) => (
                                            <tr key={i}>
                                              <td>{Vehicle.country}</td>
                                              <td>{Vehicle.vehicle}</td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {totalCount > 10 && (
                    <Row className="g-4 mt-3">
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end">
                          <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={onClickPagination}
                            shape="rounded"
                          />
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  workflowTableData: state.CodeListReducer.workflowTableData,
  creatorViewChangeTableData: state.CodeListReducer.creatorViewChangeTableData,
  workflowCombineData: state.CodeListReducer.workflowCombineData,
  creatorEditChangeTableData: state.CodeListReducer.creatorEditChangeTableData,
  loading: state.CodeListReducer.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getWorkflowTableAction: (data) => dispatch(getWorkflowTableAction(data)),
  getCreatorViewTableRequest: (data: any) => dispatch(getCreatorViewTableRequest(data)),
  getWorkflowCombineAction: (data) => dispatch(getWorkflowCombineAction(data)),
  getCreatorEditTableRequest: (data: any) =>dispatch(getCreatorEditTableRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkFlowRequest);
