import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { api } from "../config";
import { refreshTokenAction } from '../store/actions';
import { ConfigAPI } from "../helpers/url_helper";
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

// default
axios.defaults.baseURL = api.API_LOG_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = JSON.parse(sessionStorage.getItem("authUser")) ? JSON.parse(sessionStorage.getItem("authUser")).token : null;
if (token)
  axios.defaults.headers.common["Authorization"] = token;
  // axios.defaults.headers.common["Authorization"] = "Bearer " + token;

export const baseAPI = axios.create({ 
  baseURL: api.API_URL,
  withCredentials: true
});

export const baseFileUploadAPI = axios.create({
  baseURL: api.API_URL,
  withCredentials: true
});


//Add a response interceptor
baseAPI.interceptors.request.use(
  config => {
    if (!config.headers.Authorization) {
      const token = JSON.parse(JSON.stringify(sessionStorage.getItem("authToken")));
      if (token) {
        // config.headers["Content-Type"] = "application/json";
        config.headers.Authorization = ` ${token}`;
        // config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

//Add a response interceptor
baseFileUploadAPI.interceptors.request.use(
  config => {
    if (!config.headers.Authorization) {
      const token = JSON.parse(JSON.stringify(sessionStorage.getItem("authToken")));
      if (token) {
        config.headers["Content-Type"] = "multipart/form-data";
        config.headers.Authorization = ` ${token}`;
        // config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => Promise.reject(error)
);

baseAPI.interceptors.response.use(function (response) {
  console.log(response, "responseresponsegthtr");
  if (response.data.httpStatusMessage === "Access Token Invalid") {
    refreshToken();
  }
  if(response.data.httpStatusMessage === "Refresh Token Invalid") {
    sessionStorage.clear();
    history('/');
  }
  // if(response.data.httpStatusCode === 200) {
  //   sessionStorage.clear();
  // }
  return response;
}, function (error) {
  return Promise.reject(error);
});

const refreshToken = async (token) => {
  try {
      const response = await axios.post(process.env.REACT_APP_API_PATH + ConfigAPI.REFRESH_TOKEN_URL, {
          RefreshToken: sessionStorage.getItem("refreshToken") // No need to stringify and parse
      });
      sessionStorage.setItem('authToken', response?.data?.jwt);
      sessionStorage.setItem('refreshToken', response?.data?.refreshToken);
  } catch (error) {
      console.error('Error refreshing token:', error.response ? error.response.data : error.message);
  }
};

// baseAPI.interceptors.response.use(
//   async function (response) {
//     console.log(response, "responseresponse");

//     if (response.data.httpStatusCode === "200") {  
//       // Refresh token logic
//       try {
//         const refreshToken = sessionStorage.getItem("refreshToken");
//         if (refreshToken) {
//           const refreshResponse = await axios.post(ConfigAPI.REFRESH_TOKEN_URL, {
//             RefreshToken: refreshToken
//           });

//           // Access different parts of the response
//           console.log('Response Data:', refreshResponse.data); // The main response data
//           console.log('Status Code:', refreshResponse.status); // The HTTP status code
//           console.log('Response Headers:', refreshResponse.headers); // The response headers

//           // Update sessionStorage or state with new token if needed
//           sessionStorage.setItem("authToken", refreshResponse.data.newAuthToken); // example of setting new token
//         }
//       } catch (error) {
//         console.error('Error refreshing token:', error.response ? error.response.data : error.message);
//       }
//     }
//     return response;
//   }, 
//   function (error) {
//     return Promise.reject(error);
//   }
// );


// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] =  token;
  // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };