import React, { useState, useEffect } from "react";
import { StoreVisitsCharts } from "./Chart";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Input } from "reactstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { Pagination } from "@mui/material";
import DeleteModal from "../DeleteModel/DeleteModel";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from "../Loader/Loader";
import DashboardCharts from "./DashboardCharts";
import { BarChart } from './Chart';
import {
  getAllCodeAction,
  getAllCodeGroupDropdownAction,
  insertOrUpdateCodeAction,
  deleteCodeAction,
} from "../../store/actions";

const CodeAnalytics = (props: any) => {
  document.title = "D-CodeAnalytics";
  const {
    geyAllCode,
    getAllCodeGroupDropdown,
    insertOrUpdateCode,
    deleteCode,
    getLoginData,
    loading,
  } = props;
  const location = useLocation();
  const { data } = location.state || { data: [] };
  const [locationData, setLocationData] = useState(data);
  const [selectedGroup2, setSelectedGroup2] = useState(null);
  const [openModel, setOpenModel] = useState(locationData?.id ? true : false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [todo, setTodo] = useState(null);
  const [totalCount, setTotalCount] = useState<any>("");
  const [getAllCodeLists, setGetAllCodeLists] = useState<any>(null);
  const [sort, setSort] = useState("");
  const recordsPerPage = 10;
  const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [allCodeGroupDropdownDatas, setAllCodeGroupDropdownDatas] =
    useState<any>(null);
  const [editId, seteditId] = useState<any>("");
  const [editData, setEditData] = useState<any>({
    description: locationData?.description,
    name: locationData?.code,
  });
  const errors: any = {};
  const [formValidation, setFormValiodation] = useState<any>({});
  const [groupId, setgroupId] = useState<any>("");
  const LoginRoleData = sessionStorage.getItem("loginRoleAccess");
  const RoleResponse = JSON.parse(LoginRoleData);
  const Role = RoleResponse?.find(
    (data) => data?.name === "Master"
  )?.subModules?.find((val) => val?.submenuName === "Code")?.access;

  const Group =
    allCodeGroupDropdownDatas?.map((item: any) => ({
      label: item.value,
      value: item.value,
      id: item.id,
      isSelected: item.isSelected,
    })) || [];

  const ecomWidgets = [
    {
      id: 1,
      cardColor: "primary",
      label: "Total Earnings",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: "+16.24",
      counter: "559.25",
      link: "View net earnings",
      bgcolor: "success",
      icon: "bx bx-dollar-circle",
      decimals: 2,
      prefix: "$",
      suffix: "k",
    },
    {
      id: 2,
      cardColor: "secondary",
      label: "Orders",
      badge: "ri-arrow-right-down-line",
      badgeClass: "danger",
      percentage: "-3.57",
      counter: "36894",
      link: "View all orders",
      bgcolor: "info",
      icon: "bx bx-shopping-bag",
      decimals: 0,
      prefix: "",
      separator: ",",
      suffix: "",
    },
    {
      id: 3,
      cardColor: "success",
      label: "Customers",
      badge: "ri-arrow-right-up-line",
      badgeClass: "success",
      percentage: "+29.08",
      counter: "183.35",
      link: "See details",
      bgcolor: "warning",
      icon: "bx bx-user-circle",
      decimals: 2,
      prefix: "",
      suffix: "M",
    },
    {
      id: 4,
      cardColor: "info",
      label: "My Balance",
      badgeClass: "muted",
      percentage: "+0.00",
      counter: "165.89",
      link: "Withdraw money",
      bgcolor: "danger",
      icon: "bx bx-wallet",
      decimals: 2,
      prefix: "$",
      suffix: "k",
    },
  ];

  const handleEdit = (code: any) => {
    setOpenModel(true);
    setEditData(code);
    seteditId(code?.id);
    setgroupId(code?.codeGroupId);
    setSelectedGroup2({
      id: code?.codeGroupId,
      label: code?.codeGroupValue,
      value: code?.codeGroupValue,
      isSelected: code?.isActive,
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleAdd = () => {
    setOpenModel(true);
  };

  const handleSave = () => {
    if (!editData?.name) {
      errors.name = "Please fill the field";
    }
    if (!editData?.description) {
      errors.description = "Please fill the field";
    }
    if (!groupId) {
      errors.groupId = "Please fill the field";
    }
    setFormValiodation(errors);
    const Data = {
      id: editId ? editId : 0,
      name: editData?.name,
      description: editData?.description,
      codeGroupId: groupId,
      remarks: editData?.remarks,
      codeRequestId: locationData?.id,
    };
    setCurrentPage(editId ? currentPage : 1);
    if (editData?.name && editData?.description && groupId) {
      props.insertOrUpdateCodeAction(Data);
    }
  };

  useEffect(() => {
    if (insertOrUpdateCode?.httpStatusCode === 200) {
      setOpenModel(false);
      handleClear();
      setLocationData([]);
      props.getAllCodeAction({
        page: currentPage,
        search: searchKeyword,
        sort: sort,
        OrderByColumnName: "",
      });
    }
  }, [insertOrUpdateCode]);

  useEffect(() => {
    setLocationData(data);
    return () => {
      setLocationData([]);
    };
  }, [geyAllCode]);

  const handleClear = () => {
    setEditData({});
    setSelectedGroup2(null);
    setFormValiodation({});
    seteditId("");
    setgroupId("");
  };

  function handleSelectGroups2(selectedGroup2) {
    setSelectedGroup2(selectedGroup2);
    setgroupId(selectedGroup2?.id);
  }

  const handleDeleteTodo = () => {
    setDeleteModal(false);
    props.deleteCodeAction(todo);
    setCurrentPage(1);
  };

  const onClickTodoDelete = (todo: any) => {
    setTodo(todo);
    setDeleteModal(true);
  };

  const onClickPagination = (event, value) => {
    setCurrentPage(value);
  };

  const onSearchChange = (event: any) => {
    setSearchKeyword(event.target.value);
    props.getAllCodeAction({
      page: "1",
      search: event.target.value,
      sort: sort,
      OrderByColumnName: "",
    });
    setCurrentPage(1);
  };

  const onSortChange = (keys: any) => {
    const newSortOrder = sort === "asc" ? "desc" : "asc";
    setSort(newSortOrder);
    props.getAllCodeAction({
      page: currentPage,
      search: searchKeyword,
      sort: newSortOrder,
      OrderByColumnName: keys,
      SortDirection: newSortOrder,
    });
  };

  useEffect(() => {
    props.getAllCodeAction({
      page: currentPage,
      search: searchKeyword,
      sort: sort,
      OrderByColumnName: "",
    });
    setTotalCount(geyAllCode?.totalRowsCount);
  }, [currentPage, deleteCode]);

  useEffect(() => {
    setTotalCount(geyAllCode?.totalRowsCount);
  }, [geyAllCode]);

  useEffect(() => {
    setGetAllCodeLists(geyAllCode);
  }, [geyAllCode]);

  useEffect(() => {
    props.getAllCodeGroupDropdownAction();
  }, []);

  useEffect(() => {
    setAllCodeGroupDropdownDatas(getAllCodeGroupDropdown?.data);
  }, [getAllCodeGroupDropdown]);

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="D-CodeAnalytics" pageTitle="D-CodeAnalytics" />
          <Row>
            <Col xl={6} md={6}>
              <Col xl={12} md={6}>
                <Card className="card-animate">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                          Code Builder
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <h5 className={"fs-14 mb-0 text-" + "warning"}></h5>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between mt-4">
                      <div>
                        <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                          <span className="counter-value" data-target="200">
                            <CountUp
                              start={0}
                              prefix=""
                              suffix=""
                              separator=","
                              end={200}
                              decimals={0}
                              duration={4}
                            />
                          </span>
                        </h4>
                        <Link to="#" className="text-decoration-underline">
                          See details
                        </Link>
                      </div>
                      <div className="flex-shrink-0">
                        {/* <DashboardCharts seriesData={2} colors={"#45cb85d9"} /> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={12} md={6}>
                <Card className="card-animate">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                          Code Configurator
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <h5 className={"fs-14 mb-0 text-" + "warning"}></h5>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between mt-4">
                      <div>
                        <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                          <span className="counter-value" data-target="250">
                            <CountUp
                              start={0}
                              prefix=""
                              suffix=""
                              separator=","
                              end={250}
                              decimals={0}
                              duration={4}
                            />
                          </span>
                        </h4>
                        <Link to="#" className="text-decoration-underline">
                          See details
                        </Link>
                      </div>
                      <div className="flex-shrink-0">
                        {/* <DashboardCharts seriesData={40} colors={"#45cb85d9"} /> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={12} md={6}>
                <Card className="card-animate">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                          Code List
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <h5 className={"fs-14 mb-0 text-" + "warning"}></h5>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between mt-4">
                      <div>
                        <p className="fw-medium text-muted text-truncate mb-0">
                          ApprovedCode :{" "}
                          <span className="fs-22 fw-semibold ff-secondary mb-4">
                            <CountUp
                              start={0}
                              prefix=""
                              suffix=""
                              separator=","
                              end={100}
                              decimals={0}
                              duration={4}
                            />
                          </span>
                        </p>
                        <p className="fw-medium text-muted text-truncate mb-0">
                          PendingCode :{" "}
                          <span className="fs-22 fw-semibold ff-secondary mb-4">
                            <CountUp
                              start={0}
                              prefix=""
                              suffix=""
                              separator=","
                              end={987}
                              decimals={0}
                              duration={4}
                            />
                          </span>
                        </p>
                        <Link to="#" className="text-decoration-underline">
                          See details
                        </Link>
                      </div>
                      <div className="flex-shrink-0">
                        {/* <DashboardCharts seriesData={50} colors={"#45cb85d9"} /> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={12} md={6}>
                <Card className="card-animate">
                  <CardBody>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                          Total Number Of Users:
                        </p>
                      </div>
                      <div className="flex-shrink-0">
                        <h5 className={"fs-14 mb-0 text-" + "warning"}></h5>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between mt-4">
                      <div>
                        <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                          <span className="counter-value" data-target="559.25">
                            <CountUp
                              start={0}
                              prefix=""
                              suffix=""
                              separator=","
                              end={687}
                              decimals={0}
                              duration={4}
                            />
                          </span>
                        </h4>
                        <Link to="#" className="text-decoration-underline">
                          See details
                        </Link>
                      </div>
                      <div className="flex-shrink-0">
                        {/* <DashboardCharts seriesData={80} colors={"#45cb85d9"} /> */}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Col>
            <Col xl={6} md={6}>
              <Col>
                <Card className="card-height-100">
                  <CardHeader className="align-items-center d-flex">
                    <h4 className="card-title mb-0 flex-grow-1">
                      Vehicle Code Group
                    </h4>
                    <div className="flex-shrink-0">
                      <UncontrolledDropdown
                        className="card-header-dropdown"
                        direction="start"
                      >
                        <DropdownToggle
                          tag="a"
                          className="text-reset dropdown-btn"
                          role="button"
                        >
                          <span className="text-muted">
                            Report<i className="mdi mdi-chevron-down ms-1"></i>
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem>Download Report</DropdownItem>
                          <DropdownItem>Export</DropdownItem>
                          <DropdownItem>Import</DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </CardHeader>
                  <StoreVisitsCharts dataColors='["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]' />
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">Bar Chart</h4>
                  </CardHeader>
                  <CardBody>
                    <BarChart dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]' />
                  </CardBody>
                </Card>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  geyAllCode: state.MasterReducer.geyAllCode,
  getAllCodeGroupDropdown: state.MasterReducer.getAllCodeGroupDropdown,
  insertOrUpdateCode: state.MasterReducer.insertOrUpdateCode,
  deleteCode: state.MasterReducer.deleteCode,
  getLoginData:
    state.AuthenticationReducer?.getLoginData?.data?.userRoleAccessList,
  loading: state.MasterReducer.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAllCodeAction: (data: any) => {
    dispatch(getAllCodeAction(data));
  },
  getAllCodeGroupDropdownAction: (data: any) => {
    dispatch(getAllCodeGroupDropdownAction(data));
  },
  insertOrUpdateCodeAction: (data: any) => {
    dispatch(insertOrUpdateCodeAction(data));
  },
  deleteCodeAction: (data: any) => {
    dispatch(deleteCodeAction(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeAnalytics);
