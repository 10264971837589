import { CodeListActionTypes } from "./actionType";


const initialState = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    dropdownCodeList: {},
    viewTableData: {},
    submitResponse: {},
    dropdownVersionComparison: {},
    versionTableData: {},
    creatorRequestTableData: {},
    creatorViewChangeTableData: {},
    creatorEditChangeTableData: {},
    submitEditCodeList: {},
    saveEditCodeList: {},
    workflowRequestTableData: {},
    workflowCombineRequest: {},
    viewtableDecisionData: {},
    countryComparisonDropdown: {},
    countryComparisonTableData: {},
    CodeWorkflowTrackerTableData:{}
}

const CodeListReducer = (state = initialState, action) => {
    const response = action.data;
    switch (action.type) {
        case CodeListActionTypes.INIT_GET_CODE_LIST_MODULE:
            return {
                ...state, loading: false, isLoading: false, items: [], dropdownCodeList: {}
            }

        case CodeListActionTypes.GET_DROPDOWN_CODE_LIST_REQUEST:
            return { ...state, loading: true, dropdownCodeList: {} }
        case CodeListActionTypes.GET_DROPDOWN_CODE_LIST_SUCCESS:
            return { ...state, loading: false, dropdownCodeList: response.data }
        case CodeListActionTypes.GET_DROPDOWN_CODE_LIST_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        case CodeListActionTypes.GET_VIEW_CODE_LIST_TABLE_REQUEST:
            return { ...state, loading: true, viewTableData: {} }
        case CodeListActionTypes.GET_VIEW_CODE_LIST_TABLE_SUCCESS:
            return { ...state, loading: false, viewTableData: response.data }
        case CodeListActionTypes.GET_VIEW_CODE_LIST_TABLE_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        case CodeListActionTypes.SUBMIT_CODE_LIST_REQUEST:
            return { ...state, loading: true, submitResponse: {} }
        case CodeListActionTypes.SUBMIT_CODE_LIST_SUCCESS:
            return { ...state, loading: false, submitResponse: response.data }
        case CodeListActionTypes.SUBMIT_CODE_LIST_FAILED:
            return { ...state, loading: false, errors: action.error, submitResponse: action }

        case CodeListActionTypes.GET_DROPDOWN_VERSION_COMPARISON_REQUEST:
            return { ...state, loading: true, dropdownVersionComparison: {} }
        case CodeListActionTypes.GET_DROPDOWN_VERSION_COMPARISON_SUCCESS:
            return { ...state, loading: false, dropdownVersionComparison: response.data }
        case CodeListActionTypes.GET_DROPDOWN_VERSION_COMPARISON_FAILED:
            return { ...state, loading: false, errors: action.error }     
        
        case CodeListActionTypes.GET_VERSION_COMPARISON_TABLE_REQUEST:
            return { ...state, loading: true, versionTableData: {} }
        case CodeListActionTypes.GET_VERSION_COMPARISON_TABLE_SUCCESS:
            return { ...state, loading: false, versionTableData: response.data }
        case CodeListActionTypes.GET_VERSION_COMPARISON_TABLE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeListActionTypes.GET_CREATOR_TABLE_REQUEST:
            return { ...state, loading: true, creatorRequestTableData: {} }
        case CodeListActionTypes.GET_CREATOR_TABLE_SUCCESS:            
            return { ...state, loading: false, creatorRequestTableData: response.data }
        case CodeListActionTypes.GET_CREATOR_TABLE_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        case CodeListActionTypes.GET_CREATOR_VIEW_TABLE_REQUEST:
            return { ...state, loading: true, creatorViewChangeTableData: {} }
        case CodeListActionTypes.GET_CREATOR_VIEW_TABLE_SUCCESS:            
            return { ...state, loading: false, creatorViewChangeTableData: response.data }
        case CodeListActionTypes.GET_CREATOR_VIEW_TABLE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeListActionTypes.GET_CREATOR_EDIT_TABLE_REQUEST:
            return { ...state, loading: true, creatorEditChangeTableData: {} }
        case CodeListActionTypes.GET_CREATOR_EDIT_TABLE_SUCCESS:            
            return { ...state, loading: false, creatorEditChangeTableData: response.data }
        case CodeListActionTypes.GET_CREATOR_EDIT_TABLE_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        case CodeListActionTypes.SUBMIT_EDIT_CODE_LIST_REQUEST:
            return { ...state, loading: true, submitEditCodeList: {} }
        case CodeListActionTypes.SUBMIT_EDIT_CODE_LIST_SUCCESS:            
            return { ...state, loading: false, submitEditCodeList: response.data }
        case CodeListActionTypes.SUBMIT_EDIT_CODE_LIST_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        case CodeListActionTypes.SAVE_EDIT_CODE_LIST_REQUEST:
            return { ...state, loading: true, saveEditCodeList: {} }
        case CodeListActionTypes.SAVE_EDIT_CODE_LIST_SUCCESS:            
            return { ...state, loading: false, saveEditCodeList: response.data }
        case CodeListActionTypes.SAVE_EDIT_CODE_LIST_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        case CodeListActionTypes.GET_WORKFLOW_TABLE_REQUEST:
            return { ...state, loading: true, workflowTableData: {} }
        case CodeListActionTypes.GET_WORKFLOW_TABLE_SUCCESS:            
            return { ...state, loading: false, workflowTableData: response.data }
        case CodeListActionTypes.GET_WORKFLOW_TABLE_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        case CodeListActionTypes.GET_WORKFLOW_COMBINE_REQUEST:
            return { ...state, loading: true, workflowCombineData: {} }
        case CodeListActionTypes.GET_WORKFLOW_COMBINE_SUCCESS:
            return { ...state, loading: false, workflowCombineData: response }
        case CodeListActionTypes.GET_WORKFLOW_COMBINE_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        case CodeListActionTypes.GET_VIEW_CHANGES_DECISION_REQUEST:
            return { ...state, loading: true, viewChangesDecisionData: {} }
        case CodeListActionTypes.GET_VIEW_CHANGES_DECISION_SUCCESS:
            return { ...state, loading: false, viewChangesDecisionData: response }
        case CodeListActionTypes.GET_VIEW_CHANGES_DECISION_FAILED:
            return { ...state, loading: false, errors: action.error }  
        
        case CodeListActionTypes.GET_COUNTRY_COMPARISON_DROPDOWN_REQUEST:
            return { ...state, loading: true, countryComparisonDropdown: {} }
        case CodeListActionTypes.GET_COUNTRY_COMPARISON_DROPDOWN_SUCCESS:
            return { ...state, loading: false, countryComparisonDropdown: response.data }
        case CodeListActionTypes.GET_COUNTRY_COMPARISON_DROPDOWN_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        case CodeListActionTypes.GET_COUNTRY_COMPARISON_TABLE_REQUEST:
            return { ...state, loading: true, countryComparisonTableData: {} }
        case CodeListActionTypes.GET_COUNTRY_COMPARISON_TABLE_SUCCESS:
            return { ...state, loading: false, countryComparisonTableData: response.data }
        case CodeListActionTypes.GET_COUNTRY_COMPARISON_TABLE_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeListActionTypes.GET_CODEWORKFLOWTRACKER_TABLEDATA_REQUEST:
            return { ...state, loading: true, CodeWorkflowTrackerTableData: {} }
        case CodeListActionTypes.GET_CODEWORKFLOWTRACKER_TABLEDATA_SUCCESS:
            return { ...state, loading: false, CodeWorkflowTrackerTableData: response.data }
        case CodeListActionTypes.GET_CODEWORKFLOWTRACKER_TABLEDATA_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        default: {
            return state;
        }
    }
};


export default CodeListReducer;
