export const codeListData = [
    {
        "codeId": 2309,
        "codeGroup": "Accessories",
        "codeDescription": "2 wheel chocks",
        "code": "Y28",
        "notes": null,
        "isImplemented": false,
        "remarks": null,
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": "S",
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2311,
        "codeGroup": "Accessories",
        "codeDescription": "Hazard warning triangle",
        "code": "Y44",
        "notes": null,
        "isImplemented": false,
        "remarks": null,
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": "S",
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2312,
        "codeGroup": "Accessories",
        "codeDescription": "Hydraulic jack, 12t/20t",
        "code": "Y43",
        "notes": null,
        "isImplemented": false,
        "remarks": null,
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": "O",
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2313,
        "codeGroup": "Accessories",
        "codeDescription": "Wax coating",
        "code": "Y68",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2314,
        "codeGroup": "Accessories",
        "codeDescription": "First aid kit",
        "code": "Y10",
        "notes": null,
        "isImplemented": false,
        "remarks": null,
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2315,
        "codeGroup": "Axles",
        "codeDescription": "Differential Lock, Rear axle (Inter axle)",
        "code": "A71",
        "notes": null,
        "isImplemented": false,
        "remarks": null,
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2317,
        "codeGroup": "Axles",
        "codeDescription": "Meritor MT-36-610,18.0t, crown wheel 297",
        "code": "A17",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2318,
        "codeGroup": "Axles",
        "codeDescription": "Axle ratio, i=5.39 (Meritor)",
        "code": "AO2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2319,
        "codeGroup": "Axles",
        "codeDescription": "Front axle 7.5 t",
        "code": "AL3",
        "notes": null,
        "isImplemented": false,
        "remarks": null,
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": "S E3",
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2320,
        "codeGroup": "Axles",
        "codeDescription": "Axle Ratio i=6.18 (Meritor)",
        "code": "AO3",
        "notes": null,
        "isImplemented": false,
        "remarks": null,
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": "S",
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": "S E3",
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2321,
        "codeGroup": "Brakes",
        "codeDescription": "Drum brakes on front and rear axle",
        "code": "BB6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2322,
        "codeGroup": "Brakes",
        "codeDescription": "Drum brakes, front, 410/180",
        "code": "BT0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2323,
        "codeGroup": "Brakes",
        "codeDescription": "Drum brakes, rear, 410/220",
        "code": "BT5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2332,
        "codeGroup": "Cabin",
        "codeDescription": "Kerb mirror, Co-driver side",
        "code": "F63",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2336,
        "codeGroup": "Cabin",
        "codeDescription": "Window lifters, manual, driver's + \r\nco-driver's door",
        "code": "FE2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2337,
        "codeGroup": "Cabin",
        "codeDescription": "Construction type foot step",
        "code": "FE8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2343,
        "codeGroup": "Cabin",
        "codeDescription": "S- Cab",
        "code": "F07",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2346,
        "codeGroup": "Chassis",
        "codeDescription": "Stabiliser, 2nd rear axle",
        "code": "CD4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2347,
        "codeGroup": "Chassis",
        "codeDescription": "Steering system ZFLS 8098",
        "code": "ZC0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2349,
        "codeGroup": "Chassis",
        "codeDescription": "Steering Wheel 500mm dia with PU",
        "code": "VY4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2350,
        "codeGroup": "Chassis",
        "codeDescription": "Twin Steering",
        "code": "C56",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2351,
        "codeGroup": "Chassis",
        "codeDescription": "Bumper, steel version, three piece",
        "code": "C63",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2353,
        "codeGroup": "Chassis",
        "codeDescription": "Propeller Shaft Guard",
        "code": "GY1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2354,
        "codeGroup": "Chassis",
        "codeDescription": "Deletion, front under ride guard",
        "code": "C93",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2355,
        "codeGroup": "Collective codes",
        "codeDescription": "Common Power train Control (CPC)",
        "code": "ZX1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2357,
        "codeGroup": "Cross member/Towing jaw/Suspension",
        "codeDescription": "Front spring, 8.0 t",
        "code": "QC9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2361,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Batteries, 2 x 12V/120Ah, JIS, low maintenance",
        "code": "EB5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2362,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Battery cutoff switch",
        "code": "EL9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2363,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Voltage transformer, 24V/12V, 20A",
        "code": "EO1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2364,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "12V-socket, new positioned in dashboard",
        "code": "EZ4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2366,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Additional 12V connector - 1 nos.",
        "code": "EZ0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2369,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Pre-wiring, 12V radio, retrofit",
        "code": "EZ5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2370,
        "codeGroup": "Engine",
        "codeDescription": "Alternator 24 V/80 A",
        "code": "M41",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2371,
        "codeGroup": "Engine",
        "codeDescription": "Engine number, stamped-in",
        "code": "M78",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2372,
        "codeGroup": "Engine",
        "codeDescription": "Exhaust brake, with constantly-open throttle valve",
        "code": "MB1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2375,
        "codeGroup": "Engine",
        "codeDescription": "Exhaust brake, engageable/disengageable",
        "code": "MS2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2378,
        "codeGroup": "Engine",
        "codeDescription": "Radiator protection, bottom",
        "code": "M74",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2379,
        "codeGroup": "Engine",
        "codeDescription": "Speed limiter, electronic, 70 km/h",
        "code": "V69",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2380,
        "codeGroup": "Engine",
        "codeDescription": "Speed limiter, electronic, 80 km/h",
        "code": "MT7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2381,
        "codeGroup": "Engine",
        "codeDescription": "Engine version, Euro 3",
        "code": "MS3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2382,
        "codeGroup": "Engine",
        "codeDescription": "Engine version, Euro 4",
        "code": "MS4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2383,
        "codeGroup": "Engine",
        "codeDescription": "Engine, 6 Cylinder. In-line, 205kW(E2/E3) / 210kW(E4/E5/E6) @ 2200 rpm",
        "code": "MW4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2384,
        "codeGroup": "Engine",
        "codeDescription": "Air compressor, 318 CC",
        "code": "V73",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2385,
        "codeGroup": "Engine",
        "codeDescription": "Air compressor, 352 CC",
        "code": "V75",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2386,
        "codeGroup": "Engine",
        "codeDescription": "Turbocharger, 11 cm2, titanium compressor turbine wheel",
        "code": "MP9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2387,
        "codeGroup": "Engine",
        "codeDescription": "Bio fuel B7 Compatibility",
        "code": "VW3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2388,
        "codeGroup": "Engine",
        "codeDescription": "Engine ECU, MR2, Local Source",
        "code": "VU9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2389,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Fuel pre-filter, with water separator",
        "code": "K46",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2392,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Main Tank, round, 240l (tank capacity)/ 225l (filling capacity)",
        "code": "VZ3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2393,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Main Tank, round, 260l (tank capacity)/ 240l (filling capacity)",
        "code": "VZ5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2396,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Exhaust - vertical, behind cab",
        "code": "K68",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2397,
        "codeGroup": "Gear Box",
        "codeDescription": "Clutch assembly, D430, SAE 1 (High Life Time)",
        "code": "GY9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2399,
        "codeGroup": "Gear Box",
        "codeDescription": "Gearshift, mechanical",
        "code": "GS1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2400,
        "codeGroup": "Gear Box",
        "codeDescription": "Transmission G 131-9/14.57-1.0",
        "code": "GT1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2401,
        "codeGroup": "Heating/Ventilation",
        "codeDescription": "Construction-site filter",
        "code": "HF1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2403,
        "codeGroup": "Heating/Ventilation",
        "codeDescription": "Air conditioning system(HVAC)",
        "code": "H03",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2404,
        "codeGroup": "Heating/Ventilation",
        "codeDescription": "Demister",
        "code": "HK5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2408,
        "codeGroup": "Instrument cluster",
        "codeDescription": "Speedometer, km",
        "code": "J10",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2412,
        "codeGroup": "Internal Codes",
        "codeDescription": "Tipper Chassis( Construction)",
        "code": "IC2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2413,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 4500 mm",
        "code": "IR6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2414,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel arrangement 8x4/4",
        "code": "IA7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2415,
        "codeGroup": "Internal Codes",
        "codeDescription": "31.0-tonner",
        "code": "IY9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2418,
        "codeGroup": "Lamps",
        "codeDescription": "Headlamp range adjuster",
        "code": "L35",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2419,
        "codeGroup": "Lamps",
        "codeDescription": "Side lamps on roof",
        "code": "L40",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2420,
        "codeGroup": "Lamps",
        "codeDescription": "Working lamp, near rear axle area, Construction",
        "code": "U80",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2422,
        "codeGroup": "Lamps",
        "codeDescription": "Headlamps, left side traffic",
        "code": "L10",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2424,
        "codeGroup": "Lamps",
        "codeDescription": "LED tail lamps",
        "code": "LH7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2425,
        "codeGroup": "Lamps",
        "codeDescription": "Provision for Rotating beacon, only wiring & switch",
        "code": "LV1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2428,
        "codeGroup": "Load body",
        "codeDescription": "Body lifting position indication (audible & visual signal)",
        "code": "JK7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2429,
        "codeGroup": "Load body",
        "codeDescription": "Mounting parts on frame/hole pattern",
        "code": "CR4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2431,
        "codeGroup": "PTO",
        "codeDescription": "PTO, lay shaft MB, 1c (121-1c, Spline)",
        "code": "N06",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2434,
        "codeGroup": "Seats",
        "codeDescription": "Driver's seat, air-sprung.",
        "code": "S15",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2435,
        "codeGroup": "Seats",
        "codeDescription": "Dual Co-Driver Seat, Static, Not Adjustable",
        "code": "SG8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2438,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Label for fuse box, English",
        "code": "X36",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2439,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "HDT made in Chennai/ India",
        "code": "XR9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2441,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Noise reduction measures",
        "code": "X57",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2442,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "FUSO branding, entire truck",
        "code": "V31",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2443,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Labels/publications, English",
        "code": "X42",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2444,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Model identification, acc. to FUSO digit concept",
        "code": "XA0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2445,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Export code",
        "code": "IL2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2446,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Right Hand Drive Version",
        "code": "IK8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2447,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "DICV Export (KARMA)",
        "code": "XR3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2448,
        "codeGroup": "Wheels",
        "codeDescription": "Spare wheel carrier - Side",
        "code": "R61",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2449,
        "codeGroup": "Accessories",
        "codeDescription": "Racing Strips",
        "code": "VS0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2450,
        "codeGroup": "Accessories",
        "codeDescription": "A-pillar trim (Blackened A Pillar)",
        "code": "VV1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2451,
        "codeGroup": "Brakes",
        "codeDescription": "Global ABS",
        "code": "UP9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2452,
        "codeGroup": "Brakes",
        "codeDescription": "Anti-lock braking system (ABS)",
        "code": "B02",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2453,
        "codeGroup": "Cabin",
        "codeDescription": "Black Interiors",
        "code": "VU7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2454,
        "codeGroup": "Cabin",
        "codeDescription": "New Front face + Doors with lowered handle",
        "code": "U02",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2455,
        "codeGroup": "Cabin",
        "codeDescription": "Front mirror, aerodynamic",
        "code": "FX7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2456,
        "codeGroup": "Cabin",
        "codeDescription": "Central locking system",
        "code": "FZ1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2457,
        "codeGroup": "Cabin",
        "codeDescription": "Retro reflective indicator on all sides",
        "code": "XO1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2458,
        "codeGroup": "Cabin",
        "codeDescription": "Rear Fixed Window (Both driver & co-driver side)",
        "code": "VW0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2460,
        "codeGroup": "Chassis",
        "codeDescription": "Viscous cooling fan, electronic",
        "code": "V49",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2461,
        "codeGroup": "Chassis",
        "codeDescription": "Protective device, side (as kit for cab chassis)",
        "code": "C57",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2462,
        "codeGroup": "Chassis",
        "codeDescription": "Deletion, protection device, side",
        "code": "CC9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2463,
        "codeGroup": "DGMS Package (Only for India)",
        "codeDescription": "Semi automatic Fire suppression system",
        "code": "YY0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2464,
        "codeGroup": "DGMS Package (Only for India)",
        "codeDescription": "Proximity warning device",
        "code": "JW4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2465,
        "codeGroup": "DGMS Package (Only for India)",
        "codeDescription": "Auto dipping system",
        "code": "LZ1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2466,
        "codeGroup": "DGMS Package (Only for India)",
        "codeDescription": "Reverse audio visual alarm",
        "code": "JW1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2467,
        "codeGroup": "DGMS Package (Only for India)",
        "codeDescription": "Tail gate protection",
        "code": "CC7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2468,
        "codeGroup": "DGMS Package (Only for India)",
        "codeDescription": "Load Indicator and Recorder",
        "code": "JK8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2470,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Buzzer, Differential Lock",
        "code": "U07",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2471,
        "codeGroup": "Engine",
        "codeDescription": "Speed limiter - Tamper proof",
        "code": "MD6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2472,
        "codeGroup": "Engine",
        "codeDescription": "Speed limiter, electronic, 60 km/h",
        "code": "V67",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2473,
        "codeGroup": "Instrument cluster",
        "codeDescription": "Cubic printed Dashboard",
        "code": "VU8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2474,
        "codeGroup": "Instrument cluster",
        "codeDescription": "Music System (Radio with USB connection)",
        "code": "EG9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2475,
        "codeGroup": "Instrument cluster",
        "codeDescription": "Driver Attention Monitoring System",
        "code": "JJ0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2476,
        "codeGroup": "Instrument cluster",
        "codeDescription": "Cruise Control",
        "code": "MD9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2477,
        "codeGroup": "Instrument cluster",
        "codeDescription": "Rear vision system",
        "code": "EM0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2480,
        "codeGroup": "Lamps",
        "codeDescription": "Fog lamp, Below bumper",
        "code": "L16",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2481,
        "codeGroup": "Lamps",
        "codeDescription": "Head Lamp, Intergral Dual Chamber",
        "code": "VX3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2482,
        "codeGroup": "Lamps",
        "codeDescription": "LED Lamps, for Cab and Chassis [1)Tail lamp, 2)Side marker on chassis for Construction and Tractor only, 3)Side repeaters on fender & 4)Roof marker on cab]",
        "code": "L45",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2496,
        "codeGroup": "Seats",
        "codeDescription": "New fabric (Facelift)",
        "code": "SS5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2497,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "BharatBenz branding",
        "code": "V30",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2498,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Signs/Publications in 2 languages Hindi/English",
        "code": "XO0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2499,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Model identification, acc. to weight variant (BharatBENZ , Mercedes-Benz & Freightliner concept)",
        "code": "X38",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2503,
        "codeGroup": "Wheels",
        "codeDescription": "Tire Size 12.00 x 20 (Front axle Rib Type)",
        "code": "IZ7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2506,
        "codeGroup": "Cabin",
        "codeDescription": "Pedal footrest",
        "code": "FM8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2507,
        "codeGroup": "Engine",
        "codeDescription": "Engine version, Euro 2",
        "code": "MS0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2508,
        "codeGroup": "Lamps",
        "codeDescription": "Auxilary lamp within bumper (21W)",
        "code": "CM3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2509,
        "codeGroup": "Load body",
        "codeDescription": "Max. body width: 2500mm",
        "code": "VZ2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2510,
        "codeGroup": "PTO",
        "codeDescription": "PTO, lay shaft MB, 1b (121-1b, Flange)",
        "code": "N04",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2511,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "FUSO branding, with tri-diamond logo",
        "code": "V29",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2512,
        "codeGroup": "Cross member/Towing jaw/Suspension",
        "codeDescription": "Front spring, 7.5 t",
        "code": "QC4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2514,
        "codeGroup": "Wheels",
        "codeDescription": "Tyres, Tubeless, size 315/80R22.5, Front Rib Rear Lug Pattern",
        "code": "(I72/I73)",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2515,
        "codeGroup": "Wheels",
        "codeDescription": "Tyre size 12.00x20 Mining (Lug type, front & rear)",
        "code": "IZ8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2516,
        "codeGroup": "Wheels",
        "codeDescription": "Tapered rims, 8.50x20",
        "code": "R26",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2517,
        "codeGroup": "Wheels",
        "codeDescription": "Tapered rims, 9.00x22.5",
        "code": "R38",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2520,
        "codeGroup": "Axles",
        "codeDescription": "Axle ratio i=5.875",
        "code": "AG8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2521,
        "codeGroup": "Brakes",
        "codeDescription": "Drum brakes, front, 410/220",
        "code": "BT4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2522,
        "codeGroup": "Chassis",
        "codeDescription": "Stabilizer, 1st rear axle",
        "code": "C42",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2523,
        "codeGroup": "Chassis",
        "codeDescription": "Deletion, rear underride guard",
        "code": "C95",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2524,
        "codeGroup": "Chassis",
        "codeDescription": "Steering system ZF 8046 (22.4:1)",
        "code": "CL1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2525,
        "codeGroup": "Chassis",
        "codeDescription": "Steering system Rane TRW HFB 72",
        "code": "CL2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2527,
        "codeGroup": "Cross member/Towing jaw/Suspension",
        "codeDescription": "Front spring, 6.6t",
        "code": "QC0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2528,
        "codeGroup": "Engine",
        "codeDescription": "Speed limiter, 90 km/h",
        "code": "MD4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2529,
        "codeGroup": "Engine",
        "codeDescription": "Engine, 6 Cylinder. In-line, 170kW(E2/E3) / 175kW(E4/E5) @ 2200 rpm",
        "code": "MW1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2530,
        "codeGroup": "Engine",
        "codeDescription": "Air compressor, 238 CC",
        "code": "V74",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2531,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Exhaust tail pipe end downwards",
        "code": "KH8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2532,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Main Tank, round, 255l (tank capacity)/ 240l (filling capacity)",
        "code": "VZ4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2533,
        "codeGroup": "Gear Box",
        "codeDescription": "Clutch assembly, D395, SAE 2",
        "code": "GY7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2534,
        "codeGroup": "Gear Box",
        "codeDescription": "Transmission G 85-6/6.7- 0.73",
        "code": "GC6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2535,
        "codeGroup": "Internal Codes",
        "codeDescription": "Concrete Mixer Chassis",
        "code": "IC3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2536,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 3600 mm",
        "code": "IQ1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2537,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel arrangement 4x2",
        "code": "IA0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2538,
        "codeGroup": "Internal Codes",
        "codeDescription": "16.0-tonner",
        "code": "IY8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2539,
        "codeGroup": "Load body",
        "codeDescription": "Rear marking plate on tail door (Non-ECE)",
        "code": "YC3-V76",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2543,
        "codeGroup": "PTO",
        "codeDescription": "Coupling, flexible, for engine takeoff (loose)",
        "code": "N54",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2544,
        "codeGroup": "PTO",
        "codeDescription": "PTO MB 60 1c, MPA, spline",
        "code": "NL8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2545,
        "codeGroup": "Seats",
        "codeDescription": "Berth Foldable ( Extended Day cab)",
        "code": "SL3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2549,
        "codeGroup": "Wheels",
        "codeDescription": "Tire Size10.00 x 20 (Front axle Rib type)",
        "code": "I09",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2552,
        "codeGroup": "Wheels",
        "codeDescription": "Tire Size  10.00 R 20 (Front axle Rib type)",
        "code": "I30",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2555,
        "codeGroup": "Wheels",
        "codeDescription": "Tapered rims, 7.50x20",
        "code": "R23",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2556,
        "codeGroup": "Chassis",
        "codeDescription": "Front under ride guard",
        "code": "C73",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2557,
        "codeGroup": "Chassis",
        "codeDescription": "Rear underride guard",
        "code": "C71",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2558,
        "codeGroup": "Chassis",
        "codeDescription": "Frame extension - ROH + 300 mm",
        "code": "VK6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2559,
        "codeGroup": "Engine",
        "codeDescription": "Turbocharger, 13 cm2, Cast aluminium compressor turbine wheel (Non-Wastegate)",
        "code": "V79",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2560,
        "codeGroup": "Load body",
        "codeDescription": "Subframe with Hydraulic Kit",
        "code": "VK9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2561,
        "codeGroup": "Internal Codes",
        "codeDescription": "15.0-tonner",
        "code": "IU8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2562,
        "codeGroup": "Cabin",
        "codeDescription": "Power windows for driver's/co-driver's door",
        "code": "FE5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2563,
        "codeGroup": "Cabin",
        "codeDescription": "Cabin Coloured Bumper",
        "code": "VW2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2564,
        "codeGroup": "Cabin",
        "codeDescription": "Side wall trim",
        "code": "V07",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2565,
        "codeGroup": "Chassis",
        "codeDescription": "Protective device, side (body mounted, Rigid)",
        "code": "C57+CR4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2566,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "RMC Chassis for 4 Cu.m mixer",
        "code": "VS8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2567,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "RMC, Wet Type",
        "code": "VX1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2568,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "500 Lit Water Tank",
        "code": "VU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2569,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "External Engine RPM Control",
        "code": "MT5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2570,
        "codeGroup": "Lamps",
        "codeDescription": "Auxilary lamp within bumper (70W)",
        "code": "VK4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2571,
        "codeGroup": "PTO",
        "codeDescription": "Engine takeoff, rear, torque 600 Nm.",
        "code": "N52",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2572,
        "codeGroup": "Wheels",
        "codeDescription": "Tyres, tubeless, size 295/80R22.5, front/trail.axle",
        "code": "I64",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2573,
        "codeGroup": "Wheels",
        "codeDescription": "Tyres, tubeless, size 295/80R22.5, rear axle",
        "code": "I65",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2574,
        "codeGroup": "Wheels",
        "codeDescription": "Tapered rims, 8.25x22.5",
        "code": "R37",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2575,
        "codeGroup": "Axles",
        "codeDescription": "Rear Axle IRT390-11",
        "code": "U97",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2576,
        "codeGroup": "Axles",
        "codeDescription": "Rear Axle IRT390T-11",
        "code": "U98",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2577,
        "codeGroup": "Axles",
        "codeDescription": "Axle ratio i=4.778",
        "code": "AI7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2578,
        "codeGroup": "Brakes",
        "codeDescription": "Drum brakes, rear, 410/180",
        "code": "BT1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2580,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Main Tank, round, 290l (tank capacity)/ 265l (filling capacity)",
        "code": "VZ6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2581,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel arrangement 6x4",
        "code": "IA5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2582,
        "codeGroup": "Internal Codes",
        "codeDescription": "25.0-tonner",
        "code": "IV4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2589,
        "codeGroup": "Wheels",
        "codeDescription": "Tire Size 11.00 x 20 (Front axle Rib type)",
        "code": "I10",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2592,
        "codeGroup": "Wheels",
        "codeDescription": "Tire Size 11.00 x 20 Mining (Lug type, front and rear)",
        "code": "I11",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2593,
        "codeGroup": "Wheels",
        "codeDescription": "Tire Size11.00 R 20 (Front axle Rib type)",
        "code": "I32",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2596,
        "codeGroup": "Wheels",
        "codeDescription": "Tyres, tubeless, size 12.00R 22.5, front/trail.axle",
        "code": "I54",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2597,
        "codeGroup": "Wheels",
        "codeDescription": "Tyres, tubeless, size 12.00 R 22.5, rear axle",
        "code": "I55",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2598,
        "codeGroup": "Wheels",
        "codeDescription": "Tapered rims, 8.00x20",
        "code": "R25",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2599,
        "codeGroup": "Axles",
        "codeDescription": "Axle ratio i=5.375",
        "code": "AB9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2600,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "RMC Chassis for 6 Cu.m mixer",
        "code": "VS9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2606,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "RMC Chassis for 7 Cu.m mixer",
        "code": "VU0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2607,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "800 Lit Water Tank",
        "code": "VU6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2608,
        "codeGroup": "Internal Codes",
        "codeDescription": "25t GVW",
        "code": "XP0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2609,
        "codeGroup": "Lamps",
        "codeDescription": "Fog lamp provision with switch",
        "code": "LV4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2611,
        "codeGroup": "Seats",
        "codeDescription": "1 hinged berth at the bottom, with storage under berth",
        "code": "SM3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2612,
        "codeGroup": "Seats",
        "codeDescription": "Single Co-Driver Seat, Static, Adjustable",
        "code": "SG0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2616,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Left hand drive version",
        "code": "IL8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2627,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "RMC, Dry Type",
        "code": "VX0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2628,
        "codeGroup": "Internal Codes",
        "codeDescription": "26.5t GVW",
        "code": "V46",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2629,
        "codeGroup": "Brakes",
        "codeDescription": "Air-dryer with Heater",
        "code": "B76",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2630,
        "codeGroup": "Chassis",
        "codeDescription": "Front under ride guard, to meet ECE regulation",
        "code": "VY9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2631,
        "codeGroup": "Chassis",
        "codeDescription": "Rear underride guard, ECE compliant",
        "code": "VY8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2632,
        "codeGroup": "Chassis",
        "codeDescription": "Steering System ZF 8046 (26.2:1)",
        "code": "VS4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2633,
        "codeGroup": "Engine",
        "codeDescription": "High altitude suitability (4700m operating condition)",
        "code": "ZT9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2634,
        "codeGroup": "Engine",
        "codeDescription": "Cold start aid",
        "code": "M95",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2635,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Fuel preheating (Fuel filter with Heater)",
        "code": "VS2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2636,
        "codeGroup": "Instrument cluster",
        "codeDescription": "Tachograph, Analog",
        "code": "JN0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2637,
        "codeGroup": "Internal Codes",
        "codeDescription": "26t GVW",
        "code": "IV5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2638,
        "codeGroup": "Lamps",
        "codeDescription": "Rear Fog Lamp",
        "code": "L18",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2639,
        "codeGroup": "Seats",
        "codeDescription": "Seat belt reminder (audible & visual)",
        "code": "J58",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2640,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "ECE Compliant",
        "code": "V76",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2641,
        "codeGroup": "Wheels",
        "codeDescription": "Provisional spare wheel carrier",
        "code": "R60",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2642,
        "codeGroup": "Engine",
        "codeDescription": "Engine Version, Euro 5",
        "code": "MS5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2643,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Labels/publications, Spanish",
        "code": "X44",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2644,
        "codeGroup": "Internal Codes",
        "codeDescription": "26.9 GVW",
        "code": "VX8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2645,
        "codeGroup": "Load body",
        "codeDescription": "Rear marking plate (ECE R 70)",
        "code": "YC3+V76",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2646,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Publications, Arabic, additional",
        "code": "X53",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2647,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Model plate, Gulf states",
        "code": "XC2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2648,
        "codeGroup": "Wheels",
        "codeDescription": "Tyres, tubeless, size 295/80R22.5 (All wheel pattern)",
        "code": "(I64/I65)",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2649,
        "codeGroup": "Wheels",
        "codeDescription": "All Wheel Pattern",
        "code": "VV2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2650,
        "codeGroup": "Chassis",
        "codeDescription": "Noise Shield",
        "code": "PH7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2651,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "PSM, Body and trailer CAN ISO 11992",
        "code": "EM8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2652,
        "codeGroup": "Internal Codes",
        "codeDescription": "27.5 GVW",
        "code": "TR5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2653,
        "codeGroup": "Cabin",
        "codeDescription": "L- Cab",
        "code": "F04",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2654,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Exhaust pipe side,on frame right",
        "code": "KH5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2655,
        "codeGroup": "Wheels",
        "codeDescription": "Tyres, tubeless, size 12.00 R 22.5,(All wheel pattern)",
        "code": "(I54/",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2656,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 4200 mm",
        "code": "IQ8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2657,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "22Cu.m Tipper Body",
        "code": "VK5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2659,
        "codeGroup": "Gear Box",
        "codeDescription": "Clutch assembly, D430, SAE 1",
        "code": "GY8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2660,
        "codeGroup": "Internal Codes",
        "codeDescription": "30.0-tonner",
        "code": "VW7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2665,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "RMC Chassis for 10 Cu.m mixer",
        "code": "VU3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2666,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "RMC Chassis for 8 Cu.m mixer",
        "code": "VU1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2667,
        "codeGroup": "Brakes",
        "codeDescription": "Trailer brake, 2-line",
        "code": "B43",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2671,
        "codeGroup": "Chassis",
        "codeDescription": "Rear axle fender",
        "code": "VK7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2672,
        "codeGroup": "Chassis",
        "codeDescription": "Step, behind cab, left (Rear foot step for tractors)",
        "code": "D82",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2674,
        "codeGroup": "Cross member/Towing jaw/Suspension",
        "codeDescription": "5th-wheel coupling (for 2\" King pin dia)",
        "code": "QO1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2677,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Main Tank, round, 380l (tank capacity)/ 355l (filling capacity)",
        "code": "VZ7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2678,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Additional fuel tank 200l (tank capacity)/ 185l (filling capacity)",
        "code": "K26",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2679,
        "codeGroup": "Internal Codes",
        "codeDescription": "Semitrailer Tractor",
        "code": "IC4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2680,
        "codeGroup": "Internal Codes",
        "codeDescription": "40.0-tonner GCW",
        "code": "XP3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2683,
        "codeGroup": "Accessories",
        "codeDescription": "Front underbumper Spoiler",
        "code": "Y82",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2684,
        "codeGroup": "Accessories",
        "codeDescription": "Mirror cover",
        "code": "VS1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2685,
        "codeGroup": "Accessories",
        "codeDescription": "Standard air deflector, on cab roof",
        "code": "FK7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2686,
        "codeGroup": "Accessories",
        "codeDescription": "Sun visor, exterior, transparent",
        "code": "F57",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2687,
        "codeGroup": "Cabin",
        "codeDescription": "Compact Sleeper Cabin",
        "code": "VZ0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2688,
        "codeGroup": "Cross member/Towing jaw/Suspension",
        "codeDescription": "5th-wheel coupling (for 3.5\" King pin dia)",
        "code": "QO2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2689,
        "codeGroup": "Internal Codes",
        "codeDescription": "35.0-tonner GCW",
        "code": "V54",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2690,
        "codeGroup": "Seats",
        "codeDescription": "Fixed Berth (Compac Sleeper Cab)",
        "code": "SL1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2691,
        "codeGroup": "Brakes",
        "codeDescription": "Hand Operated Trailer Brake",
        "code": "B51",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2692,
        "codeGroup": "Internal Codes",
        "codeDescription": "37-tonner GCW",
        "code": "VX5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2693,
        "codeGroup": "Lamps",
        "codeDescription": "Rotating beacon, yellow",
        "code": "L58",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2694,
        "codeGroup": "Axles",
        "codeDescription": "Axle ratio i=4.300",
        "code": "AF6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2696,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 3300 mm",
        "code": "IP7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2697,
        "codeGroup": "Internal Codes",
        "codeDescription": "49.0-tonner GCW",
        "code": "XP4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2699,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "Petroleum Tanker 35KL",
        "code": "VJ0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2700,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "40ft Reefer with Cooling Unit",
        "code": "VK0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2701,
        "codeGroup": "Internal Codes",
        "codeDescription": "50.5-tonner GCW",
        "code": "VV8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2702,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "16Cu.m Garbage Compactor",
        "code": "VS7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2703,
        "codeGroup": "Engine",
        "codeDescription": "Speed limiter, electronic, 104 km/h",
        "code": "MK1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2704,
        "codeGroup": "Internal Codes",
        "codeDescription": "Platform Chassis (Rigid)",
        "code": "IC1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2705,
        "codeGroup": "Internal Codes",
        "codeDescription": "18.0-tonner",
        "code": "IV0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2706,
        "codeGroup": "PTO",
        "codeDescription": "PTO MB 60 1b, flange",
        "code": "NL5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2708,
        "codeGroup": "Internal Codes",
        "codeDescription": "18t GVW",
        "code": "VW8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2709,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "9KL Water Tanker",
        "code": "VJ1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2710,
        "codeGroup": "Engine",
        "codeDescription": "Turbocharger, 9 cm2, Aluminium Compressor turbine wheel",
        "code": "VX4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2711,
        "codeGroup": "Engine",
        "codeDescription": "Turbocharger, 11 cm2, Milled aluminium compressor turbine wheel (Waste-gate)",
        "code": "V78",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2712,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 5100 mm",
        "code": "IS0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2713,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "TMC (Truck Mounted Crane) 10.0 tm",
        "code": "VZ8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2714,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "TMC (Truck Mounted Crane) 15.0 tm",
        "code": "VZ9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2715,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Shorter Tail pipe",
        "code": "VX6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2717,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 6000 mm",
        "code": "IS6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2720,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel arrangement 6x2, trailing axle, twin tires",
        "code": "IA3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2722,
        "codeGroup": "Load body",
        "codeDescription": "Modular Load Body Concept",
        "code": "VX7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2728,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "TMC (Truck Mounted Crane) 18.5 tm",
        "code": "VI2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2729,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "TMC (Truck Mounted Crane) 23.5 tm",
        "code": "VI3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2730,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "Petroleum Tanker 20KL",
        "code": "VI7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2731,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "24ft Reefer with Cooling Unit",
        "code": "VJ8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2732,
        "codeGroup": "Load body",
        "codeDescription": "Loab body mounting brackets",
        "code": "C66",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2734,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "TMC (Truck Mounted Crane) 13.0 tm",
        "code": "VI0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2735,
        "codeGroup": "Lamps",
        "codeDescription": "5-Position Lamp, Roof Center, Yellow",
        "code": "VX2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2736,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "FREIGHTLINER Branding",
        "code": "VV3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2737,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 5700 mm",
        "code": "IS4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2740,
        "codeGroup": "Axles",
        "codeDescription": "Leading axle, made by TACO Hendrickson",
        "code": "AX0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2741,
        "codeGroup": "Axles",
        "codeDescription": "Leading axle, made by EGE Industries",
        "code": "AX2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2742,
        "codeGroup": "Axles",
        "codeDescription": "Twin speed rear axle",
        "code": "A35",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2744,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel arrangement 8x2/4, forward-trailing axle, twin tires",
        "code": "IK7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2746,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "Petroleum Tanker 24KL",
        "code": "VI8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2747,
        "codeGroup": "Load body",
        "codeDescription": "Rigid Body, Closed Container",
        "code": "VS5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2748,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "31ft Reefer with Cooling Unit",
        "code": "VJ9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2749,
        "codeGroup": "Chassis",
        "codeDescription": "Steering System ZFLS 8007",
        "code": "VK3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2750,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Main tank, 330 l, (steel, D-shape) / 310 l\r\n(Filling capacity)",
        "code": "KC0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2751,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 5950 mm",
        "code": "V22",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2752,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel arrangement 10x2/6",
        "code": "V53",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2753,
        "codeGroup": "Internal Codes",
        "codeDescription": "37t GVW",
        "code": "IZ0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2755,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 4800 mm",
        "code": "IR8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 2756,
        "codeGroup": "Direct application development by M&S",
        "codeDescription": "TMC (Truck Mounted Crane) 15.5 tm",
        "code": "VI1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3096,
        "codeGroup": "Chassis",
        "codeDescription": "Anti-Splash Guard, Short, Inside Fender - Front",
        "code": "VZ1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3097,
        "codeGroup": "Internal Codes",
        "codeDescription": "17.0-tonner (2 Axle Chassis, FA-7t, RA-10t)",
        "code": "VT8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3098,
        "codeGroup": "Engine",
        "codeDescription": "Engine ECU, MR2, Model CP2014",
        "code": "VT9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3099,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Mercedes-Benz Branding",
        "code": "VV4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3106,
        "codeGroup": "Cabin",
        "codeDescription": "Trims, Cab Interior, Washable",
        "code": "VF6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3107,
        "codeGroup": "Gear Box",
        "codeDescription": "Transmission G71-6/9.201 - 1.0",
        "code": "U06",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3108,
        "codeGroup": "Heating/Ventilation",
        "codeDescription": "Air Blower, with Ducts",
        "code": "VF8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3109,
        "codeGroup": "Engine",
        "codeDescription": "Turbocharger, 9 cm2, Titanium Compressor turbine wheel",
        "code": "U08",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3115,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Batteries, 2X12V / 100Ah",
        "code": "EE9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3116,
        "codeGroup": "Internal Codes",
        "codeDescription": "41.0 Tonner",
        "code": "IV9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3117,
        "codeGroup": "Seats",
        "codeDescription": "Single Co-Driver Seat, Static, Not Adjustable",
        "code": "S05",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3118,
        "codeGroup": "Gear Box",
        "codeDescription": "Transmission G90-6 / 6.7 - 0.73",
        "code": "U15",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3119,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Additional fuel tank 290l (tank capacity) / 265l (filling capacity)",
        "code": "U25",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3120,
        "codeGroup": "Engine",
        "codeDescription": "OM906,  with increased torque 950Nm, BS4",
        "code": "U10",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3134,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Batteries, 2 x 12V/150Ah",
        "code": "U24",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3137,
        "codeGroup": "Internal Codes",
        "codeDescription": "28.6t GVW",
        "code": "VF0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3138,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Main tank, round, 200l (tank capacity) / 185l (filling capacity)",
        "code": "K14",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3141,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Rated Load Variant",
        "code": "U11",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3142,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Internal Engine variable RPM control",
        "code": "U12",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3363,
        "codeGroup": "Internal Codes",
        "codeDescription": "27t GVW",
        "code": "U32",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3365,
        "codeGroup": "Internal Codes",
        "codeDescription": "16.5t GVW",
        "code": "U31",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3375,
        "codeGroup": "Engine",
        "codeDescription": "Engine, 6 Cylinder. In-line, 180 kW(E6) @ 2200 rpm",
        "code": "MW2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3376,
        "codeGroup": "Engine",
        "codeDescription": "BS VI/Euro VI Turbocharger",
        "code": "NEW",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3377,
        "codeGroup": "Engine",
        "codeDescription": "Engine Version, Euro 6",
        "code": "MS6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3378,
        "codeGroup": "Engine",
        "codeDescription": "BSVI Turbocharger",
        "code": "NEW",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3380,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "Main tank, 455 l, (steel, D-shape) / 430 l (Filling capacity)",
        "code": "KC9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3381,
        "codeGroup": "Gear Box",
        "codeDescription": "G85, imported 3rd and 4th gear synchro sleeve",
        "code": "U21",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3382,
        "codeGroup": "Gear Box",
        "codeDescription": "Single plate dry - Diaphragm, Ceramic Clutch disc",
        "code": "U23",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3385,
        "codeGroup": "Brakes",
        "codeDescription": "Anti-lock braking system (ABS), disengageable",
        "code": "BB0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3386,
        "codeGroup": "Wheels",
        "codeDescription": "Spare wheel mounting, tipper",
        "code": "R72",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3387,
        "codeGroup": "Wheels",
        "codeDescription": "Spare wheel Carrier, rear center",
        "code": "R65",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3436,
        "codeGroup": "Internal Codes",
        "codeDescription": "18.5t GVW",
        "code": "W54",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3437,
        "codeGroup": "Internal Codes",
        "codeDescription": "28.5t GVW",
        "code": "W55",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3438,
        "codeGroup": "Internal Codes",
        "codeDescription": "35.0t GVW",
        "code": "W56",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3439,
        "codeGroup": "Internal Codes",
        "codeDescription": "36.0t GVW",
        "code": "W57",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3440,
        "codeGroup": "Internal Codes",
        "codeDescription": "44.0t GVW",
        "code": "W67",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3441,
        "codeGroup": "Internal Codes",
        "codeDescription": "46.0t GCW",
        "code": "W68",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3442,
        "codeGroup": "Internal Codes",
        "codeDescription": "55.0t GCW",
        "code": "W69",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3443,
        "codeGroup": "Internal Codes",
        "codeDescription": "19.0t GVW",
        "code": "W70",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3444,
        "codeGroup": "Internal Codes",
        "codeDescription": "28.0t GVW",
        "code": "W71",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3446,
        "codeGroup": "Internal Codes",
        "codeDescription": "20.0t GVW",
        "code": "W72",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3447,
        "codeGroup": "Internal Codes",
        "codeDescription": "29.0t GVW",
        "code": "W73",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3448,
        "codeGroup": "Internal Codes",
        "codeDescription": "42.0t GVW",
        "code": "WW8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3449,
        "codeGroup": "Internal Codes",
        "codeDescription": "45.5t GCW",
        "code": "WW6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3452,
        "codeGroup": "Brakes",
        "codeDescription": "S ABS",
        "code": "UP9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3453,
        "codeGroup": "Chassis",
        "codeDescription": "Viscous cooling fan, Mechanical",
        "code": "V48",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3456,
        "codeGroup": "Cabin",
        "codeDescription": "Rear Window behind driver sliding",
        "code": "F36",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3457,
        "codeGroup": "Cross member/Towing jaw/Suspension",
        "codeDescription": "Rear Chassis suspension (Bogie)",
        "code": "WI1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3579,
        "codeGroup": "Gear Box",
        "codeDescription": "Clutch assembly, D395, SAE 2(High Life Time)",
        "code": "W02",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3612,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 5900 mm",
        "code": "IR1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3742,
        "codeGroup": "Accessories",
        "codeDescription": "Dummy",
        "code": "New",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3746,
        "codeGroup": "Cabin",
        "codeDescription": "Cab step, Extended",
        "code": "WV7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3747,
        "codeGroup": "Internal Codes",
        "codeDescription": "50.0t GCW",
        "code": "W03",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3748,
        "codeGroup": "Axles",
        "codeDescription": "Axle ratio i = 4.55 (MT-36-610)",
        "code": "AO0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3749,
        "codeGroup": "Engine",
        "codeDescription": "Bio fuel B30 Compatibility",
        "code": "V12",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3753,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Simplified Ground module",
        "code": "ED9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3754,
        "codeGroup": "Cabin",
        "codeDescription": "Rear wall trim",
        "code": "WV2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3964,
        "codeGroup": "Engine",
        "codeDescription": "Engine ECU, Model CP2015",
        "code": "U36",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3965,
        "codeGroup": "Cross member/Towing jaw/Suspension",
        "codeDescription": "Front spring, 7.0t",
        "code": "QC1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3967,
        "codeGroup": "Wheels",
        "codeDescription": "Tyre size 295/90 R 20",
        "code": "W00",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 3992,
        "codeGroup": "Chassis",
        "codeDescription": "Frame extension - ROH + 755 mm",
        "code": "V13",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4010,
        "codeGroup": "Cabin",
        "codeDescription": "Fender skirt extension",
        "code": "XW5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4018,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 5900 mm",
        "code": "IR1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4023,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Multi lingual, OSB Manual",
        "code": "VT5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4024,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "OSB Manual English",
        "code": "VT6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4027,
        "codeGroup": "Chassis",
        "codeDescription": "Stabiliser, 1st rear axle",
        "code": "CD3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4028,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Bharatz Benz - Telematics",
        "code": "U09",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4029,
        "codeGroup": "Accessories",
        "codeDescription": "Fire extinguisher",
        "code": "Y16",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4119,
        "codeGroup": "Axles",
        "codeDescription": "Front Axle IF-7.0t",
        "code": "U95",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4120,
        "codeGroup": "Axles",
        "codeDescription": "Rear Axle IR440-11",
        "code": "U96",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4121,
        "codeGroup": "Cabin",
        "codeDescription": "Sun visor, exterior, transparent",
        "code": "F57",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4126,
        "codeGroup": "Chassis",
        "codeDescription": "Anti-Splash Guard inside fender - Front",
        "code": "W11",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4127,
        "codeGroup": "Chassis",
        "codeDescription": "Stabilizer first front axle",
        "code": "W12",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4128,
        "codeGroup": "Chassis",
        "codeDescription": "Steering column support adjustable (tilt/telescopic)",
        "code": "W14",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4129,
        "codeGroup": "Chassis",
        "codeDescription": "Steering Column, Mechanical type Adjustment",
        "code": "W15",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4130,
        "codeGroup": "Chassis",
        "codeDescription": "Single circuit Steering",
        "code": "CC3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4131,
        "codeGroup": "Chassis",
        "codeDescription": "Wheel arch with short spray suppression",
        "code": "WF7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4133,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "24V-Socket",
        "code": "W24",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4134,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Reverse warning Buzzer",
        "code": "JW0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4135,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Tail Lamp mounting (Temporary location)",
        "code": "W22",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4137,
        "codeGroup": "Fuel Tank / Exhaust",
        "codeDescription": "AdBlue tank, plastic, 60l (tank capacity)/ 50l (filling capacity)",
        "code": "W21",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4138,
        "codeGroup": "Gear Box",
        "codeDescription": "Single plate dry - Diaphragm, Organic Clutch disc",
        "code": "W45",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4140,
        "codeGroup": "Lamps",
        "codeDescription": "Fog lamp provision, only wiring",
        "code": "W31",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4141,
        "codeGroup": "Lamps",
        "codeDescription": "Side markers, body mounted",
        "code": "W29",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4142,
        "codeGroup": "Load body",
        "codeDescription": "Rear marking plate on tail door (Non-ECE)",
        "code": "W42",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4143,
        "codeGroup": "Seats",
        "codeDescription": "Driver Seat, Static, Adjustable",
        "code": "W44",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4144,
        "codeGroup": "Seats",
        "codeDescription": "Seat belt (Without remainder)",
        "code": "W43",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4145,
        "codeGroup": "Weights",
        "codeDescription": "Weight variants 16.2t 2 axle-chassis (FA/RA) (6.0t/10.2t)",
        "code": "W35",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4146,
        "codeGroup": "Weights",
        "codeDescription": "Weight variants 18.5t 2 axle-chassis (FA/RA) (7.0t/11.5t)",
        "code": "W05",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4149,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Imported Ground module",
        "code": "NEW",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4150,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Exhaust brake override( Pedal operated exhaust brake)",
        "code": "U92",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4152,
        "codeGroup": "Axles",
        "codeDescription": "TAG Axle IT-10",
        "code": "U99",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4153,
        "codeGroup": "Chassis",
        "codeDescription": "Anti-Splash Guard for fender - Rear",
        "code": "W10",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4154,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "Tail lamp mounting final location",
        "code": "W23",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4155,
        "codeGroup": "Weights",
        "codeDescription": "Weight variants 28.0t 3 axle-chassis (FA/1st RA/2nd RA) (7.0t/11.2t/9.8t)",
        "code": "W06",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4158,
        "codeGroup": "Axles",
        "codeDescription": "Lift axle VD4- 6.6 (Pusher axle)",
        "code": "WI2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4159,
        "codeGroup": "Weights",
        "codeDescription": "Weight variants 35.0t 4 axle-chassis (1st FA/2nd FA/1st RA/2nd RA) (7.0t/7.0t/11.2t/9.8t)",
        "code": "V10",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4161,
        "codeGroup": "Chassis",
        "codeDescription": "Wheel arch  with Long spray suppression",
        "code": "WF8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4162,
        "codeGroup": "Lamps",
        "codeDescription": "Side markers, chassis mounted",
        "code": "W30",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4163,
        "codeGroup": "Load body",
        "codeDescription": "10.5 Cu.m Box Body",
        "code": "PY1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4164,
        "codeGroup": "Load body",
        "codeDescription": "8.5 Cu.m Box Body",
        "code": "PY0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4165,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Box Body, Steel Floor, st 52, Hidromas Hydraulics, Kailash Body",
        "code": "PY0+ZU2+ZU9+ZU4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4166,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Box Body, Steel Floor, st 52, Hidromas Hydraulics, Tranztar Body",
        "code": "PY0+ZU2+ZU9+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4167,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Box Body, Steel Floor, st 52, Hyva Hydraulics, HYVA Body",
        "code": "PY0+ZU2+ZU8+ZU3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4168,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Box Body, Steel Floor, st 52, Hyva Hydraulics, Kailash Body",
        "code": "PY0+ZU2+ZU8+ZU4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4169,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Box Body, Steel Floor, st 52, Hyva Hydraulics, Tranztar Body",
        "code": "PY0+ZU2+ZU8+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4170,
        "codeGroup": "Load body",
        "codeDescription": "16 Cu.m Box Body, Steel Floor, st 52, Hidromas Hydraulics, Kailash Body",
        "code": "PY1+ZU2+ZU9+ZU4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4171,
        "codeGroup": "Load body",
        "codeDescription": "16 Cu.m Box Body, Steel Floor, st 52, Hidromas Hydraulics, Tranztar Body",
        "code": "PY1+ZU2+ZU9+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4172,
        "codeGroup": "Load body",
        "codeDescription": "16 Cu.m Box Body, Steel Floor, st 52, Hyva Hydraulics, HYVA Body",
        "code": "PY1+ZU2+ZU8+ZU3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4173,
        "codeGroup": "Load body",
        "codeDescription": "16 Cu.m Box Body, Steel Floor, st 52, Hyva Hydraulics, Kailash Body",
        "code": "PY1+ZU2+ZU8+ZU4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4174,
        "codeGroup": "Load body",
        "codeDescription": "16 Cu.m Box Body, Steel Floor, st 52, Hyva Hydraulics, Tranztar Body",
        "code": "PY1+ZU2+ZU8+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4175,
        "codeGroup": "Weights",
        "codeDescription": "Weight variants 28.0t 3 axle-chassis (FA/1st RA/2nd RA) (7.0t/10.5t/10.5t)",
        "code": "W07",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4176,
        "codeGroup": "Load body",
        "codeDescription": "22 Cu.m Box Body, Steel Floor, st 52 Inhouse design (Hyva/Hidromas)",
        "code": "PY0+ZU2+ZU8/ZU9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4177,
        "codeGroup": "Chassis",
        "codeDescription": "Stabilizer second front axle",
        "code": "W13",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4178,
        "codeGroup": "Load body",
        "codeDescription": "18 Cu.m Box Body, Steel Floor, st 52 Hyva Hydraulics, Hyva Body",
        "code": "PY2+ZU2+ZU9+ZU3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4179,
        "codeGroup": "Load body",
        "codeDescription": "19 Cu.m Box Body, Steel Floor, st 52 Hidromas Hydraulics, Kailash Body",
        "code": "PY2+ZU2+ZU9+ZU4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4180,
        "codeGroup": "Load body",
        "codeDescription": "19 Cu.m Box Body, Steel Floor, st 52 Hidromas Hydraulics, Tranztar Body",
        "code": "PY2+ZU2+ZU9+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4181,
        "codeGroup": "Load body",
        "codeDescription": "19 Cu.m Box Body, Steel Floor, st 52 Hyva Hydraulics, Kailash Body",
        "code": "PY2+ZU2+ZU8+ZU4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4182,
        "codeGroup": "Load body",
        "codeDescription": "19 Cu.m Box Body, Steel Floor, st 52 Hyva Hydraulics, Tranztar Body",
        "code": "PY2+ZU2+ZU8+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4183,
        "codeGroup": "Load body",
        "codeDescription": "19Cu.m Box,Steel Floor,st52,Hyva Hyd, Hyva Body",
        "code": "PY2+ZU2+ZU8+ZU3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4187,
        "codeGroup": "Weights",
        "codeDescription": "Weight variants 35.0t 4 axle-chassis (1st FA/2nd FA/1st RA/2nd RA) (7.0t/7.0t/10.5t/10.5t)",
        "code": "V11",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4190,
        "codeGroup": "Weights",
        "codeDescription": "Weight variants 37.0t 5 axle-chassis (1st FA/2nd FA/Lift Axle/1st RA/2nd RA) (6.0t/6.0t/6.0t/9.5t/9.5t)",
        "code": "W39",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4191,
        "codeGroup": "Weights",
        "codeDescription": "Weight variants 42.0t 5 axle-chassis (1st FA/2nd FA/Lift Axle/1st RA/2nd RA) (7.0t/7.0t/7.0t/11.2t/9.8t)",
        "code": "NEW",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4192,
        "codeGroup": "Internal Codes",
        "codeDescription": "Rigid Tipper Vehicle platform",
        "code": "U33",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4193,
        "codeGroup": "Brakes",
        "codeDescription": "Integrated plam coupling",
        "code": "WI3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4195,
        "codeGroup": "Chassis",
        "codeDescription": "Wheel arch without spray suppresion",
        "code": "WF9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4196,
        "codeGroup": "Load body",
        "codeDescription": "16 Cu.m Scoop Body, Sandwich Floor, Raex, Hyva Hydraulics, Hyva Body",
        "code": "PY4+ZU1+ZU8+ZU3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4197,
        "codeGroup": "Load body",
        "codeDescription": "16 Cu.m Scoop Body, Sandwich Floor, Raex, Hyva Hydraulics, Tranztar Body",
        "code": "PY4+ZU1+ZU8+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4198,
        "codeGroup": "Load body",
        "codeDescription": "18 Cu.m Scoop Body, Sandwich Floor, Raex, Hidromas Hydraulics, Satrac body",
        "code": "PY5+ZU1+ZU9+ZU6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4199,
        "codeGroup": "Load body",
        "codeDescription": "18 Cu.m Scoop Body, Sandwich Floor, Raex, Hidromas Hydraulics, Tranztar body",
        "code": "PY5+ZU1+ZU9+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4200,
        "codeGroup": "Load body",
        "codeDescription": "18 Cu.m Scoop Body, Sandwich Floor, Raex, Hyva Hydraulics, Hyva body",
        "code": "PY5+ZU1+ZU8+ZU3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4201,
        "codeGroup": "Load body",
        "codeDescription": "18 Cu.m Scoop Body, Sandwich Floor, Raex, Hyva Hydraulics, Satrac body",
        "code": "PY5+ZU1+ZU8+ZU6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4202,
        "codeGroup": "Load body",
        "codeDescription": "18 Cu.m Scoop Body, Sandwich Floor, Raex, Hyva Hydraulics, Tranztar body",
        "code": "PY5+ZU1+ZU8+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4203,
        "codeGroup": "Load body",
        "codeDescription": "23 Cu.m Box Body, Steel Floor, st 52 Hyva Hydraulics, Hyva Body",
        "code": "PY0+ZU2+ZU8+ZU8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4207,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Scoop Body, Sandwich Floor, Raex, Hidromas Hydraulics, Satrac Body",
        "code": "PY3+ZU1+ZU9+ZU6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4208,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Scoop Body, Sandwich Floor, Raex, Hidromas Hydraulics, Tranztar Body",
        "code": "PY3+ZU1+ZU9+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4209,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Scoop Body, Sandwich Floor, Raex, Hyva Hydraulics, Satrac body",
        "code": "PY3+ZU1+ZU8+ZU6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4210,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Scoop Body, Sandwich Floor, st 52, Hidromas Hydraulics, Satrac Body",
        "code": "PY3+ZU2+ZU9+ZU6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4211,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Scoop Body, Sandwich Floor, st 52, Hidromas Hydraulics, Tranztar Body",
        "code": "PY3+ZU2+ZU9+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4212,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Scoop Body, Sandwich Floor, st 52, Hyva Hydraulics, Hyva Body",
        "code": "PY3+ZU2+ZU8+ZU3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4213,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Scoop Body, Sandwich Floor, st 52, Hyva Hydraulics, Tranztar Body",
        "code": "PY3+ZU2+ZU8+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4214,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Scoop Body, Sandwich Floor, Raex, Hyva Hydraulics, Hyva Body",
        "code": "PY3+ZU1+ZU8+ZU3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4215,
        "codeGroup": "Load body",
        "codeDescription": "14 Cu.m Scoop Body, Sandwich Floor, Raex, Hyva Hydraulics, Tranztar Body",
        "code": "PY3+ZU1+ZU8+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4216,
        "codeGroup": "Load body",
        "codeDescription": "16 Cu.m Scoop Body, Sandwich Floor, Raex, Hidromas Hydraulics, Tranztar Body",
        "code": "PY4+ZU1+ZU9+ZU5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4252,
        "codeGroup": "Weights",
        "codeDescription": "40.5T GCW",
        "code": "UG7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4253,
        "codeGroup": "PTO",
        "codeDescription": "PTO MB 60-2b, flange, low-position, low-speed",
        "code": "NM4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4258,
        "codeGroup": "Engine",
        "codeDescription": "Bio fuel B20 Compatibility",
        "code": "NO7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4259,
        "codeGroup": "Internal Codes",
        "codeDescription": "Wheel Base 4100 mm",
        "code": "UH6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4261,
        "codeGroup": "Weights",
        "codeDescription": "Weight variants 27.0t 3 axle-chassis (FA/1st RA/2nd RA) (7.0t/10t/10t)",
        "code": "UH8",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4264,
        "codeGroup": "Internal Codes",
        "codeDescription": "42.0t GCW",
        "code": "UH5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4289,
        "codeGroup": "Instrument cluster",
        "codeDescription": "Music system provision",
        "code": "UJ3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4290,
        "codeGroup": "Engine",
        "codeDescription": "Engine Type plate, Vietnam",
        "code": "UJ1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4309,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Manufacturing Location, Oragadam, India",
        "code": "IF1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4316,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Body shell Cabin unpainted",
        "code": "U7A",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4317,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Body shell Cabin painted",
        "code": "U7B",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4325,
        "codeGroup": "Internal Codes",
        "codeDescription": "35.6t GVW",
        "code": "UK2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4326,
        "codeGroup": "Internal Codes",
        "codeDescription": "35.5t GVW",
        "code": "UK3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4327,
        "codeGroup": "Internal Codes",
        "codeDescription": "35.4t GVW",
        "code": "UK4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4328,
        "codeGroup": "Weights/Miscellaneous",
        "codeDescription": "Defence project",
        "code": "UL3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4329,
        "codeGroup": "Load body",
        "codeDescription": "Flatbed body for Size 2 Dozer transportation",
        "code": "UL4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4330,
        "codeGroup": "Lamps",
        "codeDescription": "Two lamp mounted on Flatbed front wall",
        "code": "UK6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4331,
        "codeGroup": "Accessories",
        "codeDescription": "Firearms bracket, in cab",
        "code": "UL7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4332,
        "codeGroup": "Accessories",
        "codeDescription": "Two 20t Hydraulic jack",
        "code": "UK5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4333,
        "codeGroup": "Accessories",
        "codeDescription": "2 no's Fire Extinguisher mounted inside Cabin & 2 no's on Flatbed",
        "code": "UL1",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4334,
        "codeGroup": "Wheels",
        "codeDescription": "Snow chain(Non skid chain) for all wheels",
        "code": "UK7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4335,
        "codeGroup": "Accessories",
        "codeDescription": "Lashing chain(25±1.3mm) with Turn buckle",
        "code": "UK6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4336,
        "codeGroup": "Accessories",
        "codeDescription": "Track choke",
        "code": "UL5",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4337,
        "codeGroup": "Engine",
        "codeDescription": "OM906 Engine, 6 Cylinder,In-line, 212kW(E4) @ 2200 rpm",
        "code": "UL0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4338,
        "codeGroup": "Cabin",
        "codeDescription": "Olive colour IS220",
        "code": "UL2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4339,
        "codeGroup": "Internal Codes",
        "codeDescription": "Oil & Lubricants Maintenance Interval chart",
        "code": "UK9",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4340,
        "codeGroup": "Accessories",
        "codeDescription": "Lashing chain(25±1.3mm) with Turn buckle",
        "code": "UL6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4342,
        "codeGroup": "PTO",
        "codeDescription": "PTO MB 60-1B, FLANGE, 90 AD PKD",
        "code": "UF4",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4343,
        "codeGroup": "Internal Codes",
        "codeDescription": "GROSS COMBINATION WEIGHT 31.8 TONNES",
        "code": "UN3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4346,
        "codeGroup": "Cabin",
        "codeDescription": "TRUCK 2020 Facelift(New face + Lowered grab handle)",
        "code": "WI6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4347,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "FasTag - Electronnic Toll Collection",
        "code": "UF3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4348,
        "codeGroup": "Electricals & Electronics",
        "codeDescription": "FasTag: 4 to 6 Axles (Truck: > 20,000 to < 60,000 GVW) & Pantone Colour code - Pink",
        "code": "UG2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4433,
        "codeGroup": "Brakes",
        "codeDescription": "Omission Trailer Retarding brake",
        "code": "US0",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4447,
        "codeGroup": "Axles",
        "codeDescription": "Axle ratio, i=6.14 (Meritor)",
        "code": "UV6",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4448,
        "codeGroup": "Axles",
        "codeDescription": "Rear axle MS185-16",
        "code": "UV7",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4462,
        "codeGroup": "Cabin",
        "codeDescription": "Mid cabin (M-cab)",
        "code": "F05",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4468,
        "codeGroup": "Seats",
        "codeDescription": "1 hinged berth at the bottom, with storage under berth (Mid cabin)",
        "code": "UG3",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4469,
        "codeGroup": "Internal Codes",
        "codeDescription": "BB friendly application",
        "code": "New",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4471,
        "codeGroup": "Internal Codes",
        "codeDescription": "BB friendly application",
        "code": "DT2",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4480,
        "codeGroup": "siva",
        "codeDescription": "siva",
        "code": "siva",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4488,
        "codeGroup": "Accessories",
        "codeDescription": "siva",
        "code": "siva",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    },
    {
        "codeId": 4491,
        "codeGroup": "Accessories",
        "codeDescription": "siva",
        "code": "siva",
        "notes": null,
        "isImplemented": false,
        "remarks": "",
        "codeListVehicleMapping": [
            {
                "vehicleId": 92,
                "vehicleName": "C400.760-1217 C / 4x2 WB 3160",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 110,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 114,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 117,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    },
                    {
                        "vehicleCountryGroupId": 162,
                        "countryGroupId": 1,
                        "countryGroup": "Indonesia ",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 115,
                "vehicleName": "C400.300-2528R 5700WB 6x4",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 122,
                        "countryGroupId": 18,
                        "countryGroup": "EGYPT",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            },
            {
                "vehicleId": 18,
                "vehicleName": "C400.212-2528 R / 6x2 WB 4500",
                "codeListCountryGroupMapping": [
                    {
                        "vehicleCountryGroupId": 131,
                        "countryGroupId": 3,
                        "countryGroup": "Nepal",
                        "optionValue": null,
                        "isUpdatedOnPreviousVersion": false
                    }
                ],
                "codeListCountryGroupMappingDict": null
            }
        ]
    }
]