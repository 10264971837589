// Import the action types
import { MastersActionTypes } from "./actionType";

// Define the action creators
export const initGetMastersDetailsModule = (data) => (
    {
        type: MastersActionTypes.INIT_GET_MASTERS_DETAILS_MODULE,
    });

export const getAllCountryGroupAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_COUNTRYGROUP_REQUEST,
        data
    });

export const getAllCountryGroupSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_COUNTRYGROUP_SUCCESS,
        data
    };
};

export const getAllCountryGroupFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_COUNTRYGROUP_FAILED,
    error
});

export const getCountryGroupByIdAction = (data) => (
    {
        type: MastersActionTypes.GET_COUNTRYGROUP_BY_ID_REQUEST,
        data
    });

export const getCountryGroupByIdSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_COUNTRYGROUP_BY_ID_SUCCESS,
        data
    };
};

export const getCountryGroupByIdFailure = (error) => ({
    type: MastersActionTypes.GET_COUNTRYGROUP_BY_ID_FAILED,
    error
});

export const insertOrUpdateCountryGroupAction = (data) => (
    {
        type: MastersActionTypes.POST_COUNTRYGROUP_INSERT_OR_UPDATE_REQUEST,
        data
    });

export const insertOrUpdateCountryGroupSuccess = (data) => {
    return {
        type: MastersActionTypes.POST_COUNTRYGROUP_INSERT_OR_UPDATE_SUCCESS,
        data
    };
};

export const insertOrUpdateCountryGroupFailure = (error) => ({
    type: MastersActionTypes.POST_COUNTRYGROUP_INSERT_OR_UPDATE_FAILED,
    error
});

export const deleteCountryGroupAction = (data) => (
    {
        type: MastersActionTypes.GET_DELETE_COUNTRYGROUP_REQUEST,
        data
    });

export const deleteCountryGroupSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_DELETE_COUNTRYGROUP_SUCCESS,
        data
    };
};

export const deleteCountryGroupFailure = (error) => ({
    type: MastersActionTypes.GET_DELETE_COUNTRYGROUP_FAILED,
    error
});

export const getAllCountryAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_COUNTRY_REQUEST,
        data
    });

export const getAllCountrySuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_COUNTRY_SUCCESS,
        data
    };
};

export const getAllCountryFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_COUNTRY_FAILED,
    error
});

export const insertOrUpdateCountryAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_COUNTRY_REQUEST,
        data
    });

export const insertOrUpdateCountrySuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_COUNTRY_SUCCESS,
        data
    };
};

export const insertOrUpdateCountryFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_COUNTRY_FAILED,
    error
});

export const deleteCountryAction = (data) => (
    {
        type: MastersActionTypes.DELETE_COUNTRY_REQUEST,
        data
    });

export const deleteCountrySuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_COUNTRY_SUCCESS,
        data
    };
};

export const deleteCountryFailure = (error) => ({
    type: MastersActionTypes.DELETE_COUNTRY_FAILED,
    error
});

export const getCountryGroupDropdownAction = (data) => (
    {
        type: MastersActionTypes.GET_COUNTRYGROUP_DROPDOWN_REQUEST,
        data
    });

export const getCountryGroupDropdownSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_COUNTRYGROUP_DROPDOWN_SUCCESS,
        data
    };
};

export const getCountryGroupDropdownFailure = (error) => ({
    type: MastersActionTypes.GET_COUNTRYGROUP_DROPDOWN_FAILED,
    error
});

export const getAllVehicleTypeAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_VEHICLETYPE_REQUEST,
        data
    });

export const getAllVehicleTypeSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_VEHICLETYPE_SUCCESS,
        data
    };
};

export const getAllVehicleTypeFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_VEHICLETYPE_FAILED,
    error
});

export const insertOrUpdateVehicleTypeAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLETYPE_REQUEST,
        data
    });

export const insertOrUpdateVehicleTypeSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLETYPE_SUCCESS,
        data
    };
};

export const insertOrUpdateVehicleTypeFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLETYPE_FAILED,
    error
});

export const deleteVehicleTypeAction = (data) => (
    {
        type: MastersActionTypes.DELETE_VEHICLE_TYPE_REQUEST,
        data
    });

export const deleteVehicleTypeSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_VEHICLE_TYPE_SUCCESS,
        data
    };
};

export const deleteVehicleTypeFailure = (error) => ({
    type: MastersActionTypes.DELETE_VEHICLE_TYPE_FAILED,
    error
});

export const getAllVehicleGroupAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_VEHICLEGROUP_REQUEST,
        data
    });

export const getAllVehicleGroupSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_VEHICLEGROUP_SUCCESS,
        data
    };
};

export const getAllVehicleGroupFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_VEHICLEGROUP_FAILED,
    error
});

export const getAllVehicleGroupDropdownAction = (data) => (
    {
        type: MastersActionTypes.GET_All_VEHICLEGROUP_DROPDOWN_REQUEST,
        data
    });

export const getAllVehicleGroupDropdownSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_All_VEHICLEGROUP_DROPDOWN_SUCCESS,
        data
    };
};

export const  getAllVehicleGroupDropdownFailure = (error) => ({
    type: MastersActionTypes.GET_All_VEHICLEGROUP_DROPDOWN_FAILED,
    error
});

export const insertOrUpdateVehicleGroupAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLEGROUP_REQUEST,
        data
    });

export const insertOrUpdateVehicleGroupSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLEGROUP_SUCCESS,
        data
    };
};

export const insertOrUpdateVehicleGroupFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLEGROUP_FAILED,
    error
});

export const deleteVehicleGroupAction = (data) => (
    {
        type: MastersActionTypes.DELETE_VEHICLE_GROUP_REQUEST,
        data
    });

export const deleteVehicleGroupSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_VEHICLE_GROUP_SUCCESS,
        data
    };
};

export const deleteVehicleGroupFailure = (error) => ({
    type: MastersActionTypes.DELETE_VEHICLE_GROUP_FAILED,
    error
});

export const getAllCodeGroupAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_CODEGROUP_REQUEST,
        data
    });

export const getAllCodeGroupSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_CODEGROUP_SUCCESS,
        data
    };
};

export const getAllCodeGroupFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_CODEGROUP_FAILED,
    error
});

export const insertOrUpdateCodeGroupAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_CODE_GROUP_REQUEST,
        data
    });

export const insertOrUpdateCodeGroupSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_CODE_GROUP_SUCCESS,
        data
    };
};

export const insertOrUpdateCodeGroupFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_CODE_GROUP_FAILED,
    error
});

export const deleteCodeGroupAction = (data) => (
    {
        type: MastersActionTypes.DELETE_CODE_GROUP_REQUEST,
        data
    });

export const deleteCodeGroupSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_CODE_GROUP_SUCCESS,
        data
    };
};

export const deleteCodeGroupFailure = (error) => ({
    type: MastersActionTypes.DELETE_CODE_GROUP_FAILED,
    error
});

export const getAllVehicleCategoryAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_VEHICLE_CATEGORT_REQUEST,
        data
    });

export const getAllVehicleCategorySuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_VEHICLE_CATEGORT_SUCCESS,
        data
    };
};

export const getAllVehicleCategoryFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_VEHICLE_CATEGORT_FAILED,
    error
});

export const insertOrUpdateVehicleCategoryAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_CATEGORY_REQUEST,
        data
    });

export const insertOrUpdateVehicleCategorySuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_CATEGORY_SUCCESS,
        data
    };
};

export const insertOrUpdateVehicleCategoryFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_CATEGORY_FAILED,
    error
});

export const deleteVehicleCategoryAction = (data) => (
    {
        type: MastersActionTypes.DELETE_VEHICLE_CATEGORY_REQUEST,
        data
    });

export const deleteVehicleCategorySuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_VEHICLE_CATEGORY_SUCCESS,
        data
    };
};

export const deleteVehicleCategoryFailure = (error) => ({
    type: MastersActionTypes.DELETE_VEHICLE_CATEGORY_FAILED,
    error
});

export const getAllOptionsAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_OPTIONS_REQUEST,
        data
    });

export const getAllOptionsSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_OPTIONS_SUCCESS,
        data
    };
};

export const getAllOptionsFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_OPTIONS_FAILED,
    error
});

export const insertOrUpdateVehicleOptionsAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_OPTIONS_REQUEST,
        data
    });

export const insertOrUpdateVehicleOptionsSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_OPTIONS_SUCCESS,
        data
    };
};

export const insertOrUpdateVehicleOptionsFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_OPTIONS_FAILED,
    error
});

export const deleteOptionsAction = (data) => (
    {
        type: MastersActionTypes.DELETE_OPTIONS_REQUEST,
        data
    });

export const deleteOptionsSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_OPTIONS_SUCCESS,
        data
    };
};

export const deleteOptionsFailure = (error) => ({
    type: MastersActionTypes.DELETE_OPTIONS_FAILED,
    error
});


export const getAllTonnageAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_TONNAGE_REQUEST,
        data
    });

export const getAllTonnageSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_TONNAGE_SUCCESS,
        data
    };
};

export const getAllTonnageFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_TONNAGE_FAILED,
    error
});

export const getAllVehicleCategoryDropDownAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_VEHICLE_CATEGORY_DROPDOWN_REQUEST,
        data
    });

export const getAllVehicleCategoryDropDownSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_VEHICLE_CATEGORY_DROPDOWN_SUCCESS,
        data
    };
};

export const getAllVehicleCategoryDropDownFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_VEHICLE_CATEGORY_DROPDOWN_FAILED,
    error
});


export const getVehicleGroupByVehicleCategoryAction = (data) => (
    {
        type: MastersActionTypes.GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_REQUEST,
        data
    });

export const getVehicleGroupByVehicleCategorySuccess = (data) => {
    return {
        type: MastersActionTypes.GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_SUCCESS,
        data
    };
};

export const getVehicleGroupByVehicleCategoryFailure = (error) => ({
    type: MastersActionTypes.GET_VEHICLE_GROUP_BY_VEHICLE_CATEGORY_FAILED,
    error
});

export const insertOrUpdateTonnageAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_TONNAGE_REQUEST,
        data
    });

export const insertOrUpdateTonnageSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_TONNAGE_SUCCESS,
        data
    };
};

export const insertOrUpdateTonnageFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_TONNAGE_FAILED,
    error
});

export const getAllVehicleModelAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_VEHICLE_MODEL_REQUEST,
        data
    });

export const getAllVehicleModelSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_VEHICLE_MODEL_SUCCESS,
        data
    };
};

export const getAllVehicleModelFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_VEHICLE_MODEL_FAILED,
    error
});

export const getTonnageByVehicleCategoryDropdownAction = (data) => (
    {
        type: MastersActionTypes.GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_REQUEST,
        data
    });

export const getTonnageByVehicleCategoryDropdownSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_SUCCESS,
        data
    };
};

export const getTonnageByVehicleCategoryDropdownFailure = (error) => ({
    type: MastersActionTypes.GET_TONNAGE_BY_VEHICLE_CATEGORY_DROPDOWN_FAILED,
    error
});

export const getAllCategoriesByVehicleGroupDropdownAction = (data) => (
    {
        type: MastersActionTypes.GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_REQUEST,
        data
    });

export const getAllCategoriesByVehicleGroupDropdownSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_SUCCESS,
        data
    };
};

export const getAllCategoriesByVehicleGroupDropdownFailure = (error) => ({
    type: MastersActionTypes.GET_All_CATEGORIES_BY_VEHICLEGROUP_DROPDOWN_FAILED,
    error
});

export const getAllVehicleGroupByCategoryDropdownAction = (data) => (
    {
        type: MastersActionTypes.GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_REQUEST,
        data
    });

export const getAllVehicleGroupByCategoryDropdownSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_SUCCESS,
        data
    };
};

export const getAllVehicleGroupByCategoryDropdownFailure = (error) => ({
    type: MastersActionTypes.GET_All_VEHICLE_GROUP_BY_CATEGORY_DROPDOWN_FAILED,
    error
});

export const insertOrUpdateVehicleModelAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_MODEL_REQUEST,
        data
    });

export const insertOrUpdateVehicleModelSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_MODEL_SUCCESS,
        data
    };
};

export const insertOrUpdateVehicleModelFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_MODEL_FAILED,
    error
});

export const deleteVehicleModelAction = (data) => (
    {
        type: MastersActionTypes.DELETE_VEHICLE_MODEL_REQUEST,
        data
    });

export const deleteVehicleModelSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_VEHICLE_MODEL_SUCCESS,
        data
    };
};

export const deleteVehicleModelFailure = (error) => ({
    type: MastersActionTypes.DELETE_VEHICLE_MODEL_FAILED,
    error
});

export const getTargetMatrixAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_TARGET_MATRIX_REQUEST,
        data
    });

export const getTargetMatrixSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_TARGET_MATRIX_SUCCESS,
        data
    };
};

export const getTargetMatrixFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_TARGET_MATRIX_FAILED,
    error
});

export const getAllVehicleModelDropdownAction = (data) => (
    {
        type: MastersActionTypes.GET_All_VEHICLE_MODEL_DROPDOWN_REQUEST,
        data
    });

export const getAllVehicleModelDropdownSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_All_VEHICLE_MODEL_DROPDOWN_SUCCESS,
        data
    };
};

export const getAllVehicleModelDropdownFailure = (error) => ({
    type: MastersActionTypes.GET_All_VEHICLE_MODEL_DROPDOWN_FAILED,
    error
});

export const getVehicleModelByVehicleGroupDropdownAction = (data) => (
    {
        type: MastersActionTypes.GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_REQUEST,
        data
    });

export const getVehicleModelByVehicleGroupDropdownSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_SUCCESS,
        data
    };
};

export const getVehicleModelByVehicleGroupDropdownFailure = (error) => ({
    type: MastersActionTypes.GET_VEHICLE_MODEL_BY_VEHICLE_GROUP_FAILED,
    error
});

export const insertOrUpdateTargrtMatrixAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_TARGET_MATRIX_REQUEST,
        data
    });

export const insertOrUpdateTargrtMatrixSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_TARGET_MATRIX_SUCCESS,
        data
    };
};

export const insertOrUpdateTargrtMatrixFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_TARGET_MATRIX_FAILED,
    error
});

export const deleteTargetMatrixAction = (data) => (
    {
        type: MastersActionTypes.DELETE_TARGET_MATRIX_REQUEST,
        data
    });

export const deleteTargetMatrixSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_TARGET_MATRIX_SUCCESS,
        data
    };
};

export const deleteTargetMatrixFailure = (error) => ({
    type: MastersActionTypes.DELETE_TARGET_MATRIX_FAILED,
    error
});

export const deleteTonnageAction = (data) => (
    {
        type: MastersActionTypes.DELETE_TONNAGE_REQUEST,
        data
    });

export const deleteTonnageSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_TONNAGE_SUCCESS,
        data
    };
};

export const deleteTonnageFailure = (error) => ({
    type: MastersActionTypes.DELETE_TONNAGE_FAILED,
    error
});

export const getAllPackageMasterAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_PACKAGE_MASTER_REQUEST,
        data
    });

export const getAllPackageMasterSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_PACKAGE_MASTER_SUCCESS,
        data
    };
};

export const getAllPackageMasterFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_PACKAGE_MASTER_FAILED,
    error
});

export const getAllPackageSubitemAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_PACKAGE_SUBITEM_REQUEST,
        data
    });

export const getAllPackageSubitemSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_PACKAGE_SUBITEM_SUCCESS,
        data
    };
};

export const getAllPackageSubitemFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_PACKAGE_SUBITEM_FAILED,
    error
});

export const getAllPackageMasterDropdownAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_PACKAGE_MASTER_DROPDOWN_REQUEST,
        data
    });

export const getAllPackageMasterDropdownSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_PACKAGE_MASTER_DROPDOWN_SUCCESS,
        data
    };
};

export const getAllPackageMasterDropdownFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_PACKAGE_MASTER_DROPDOWN_FAILED,
    error
});

export const getVehicleGroupByPackageAction = (data) => (
    {
        type: MastersActionTypes.GET_VEHICLE_GROUP_BY_PACKAGE_REQUEST,
        data
    });

export const getVehicleGroupByPackageSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_VEHICLE_GROUP_BY_PACKAGE_SUCCESS,
        data
    };
};

export const getVehicleGroupByPackageFailure = (error) => ({
    type: MastersActionTypes.GET_VEHICLE_GROUP_BY_PACKAGE_FAILED,
    error
});

export const insertOrUpdatePackageSubitemAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_SUBITEM_REQUEST,
        data
    });

export const insertOrUpdatePackageSubitemSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_SUBITEM_SUCCESS,
        data
    };
};

export const insertOrUpdatePackageSubitemFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_SUBITEM_FAILED,
    error
});

export const deletePackageSubitemAction = (data) => (
    {
        type: MastersActionTypes.DELETE_PACTAGE_SUBITEM_REQUEST,
        data
    });

export const deletePackageSubitemSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_PACTAGE_SUBITEM_SUCCESS,
        data
    };
};

export const deletePackageSubitemFailure = (error) => ({
    type: MastersActionTypes.DELETE_PACTAGE_SUBITEM_FAILED,
    error
});

export const getAllCodeAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_CODE_REQUEST,
        data
    });

export const getAllCodeSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_CODE_SUCCESS,
        data
    };
};

export const getAllCodeFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_CODE_FAILED,
    error
});

export const getAllCodeGroupDropdownAction = (data) => (
    {
        type: MastersActionTypes.GET_All_CODE_GROUP_DROPDOWN_REQUEST,
        data
    });

export const getAllCodeGroupDropdownSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_All_CODE_GROUP_DROPDOWN_SUCCESS,
        data
    };
};

export const getAllCodeGroupDropdownFailure = (error) => ({
    type: MastersActionTypes.GET_All_CODE_GROUP_DROPDOWN_FAILED,
    error
});

export const insertOrUpdateCodeAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_CODE_REQUEST,
        data
    });

export const insertOrUpdateCodeSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_CODE_SUCCESS,
        data
    };
};

export const insertOrUpdateCodeFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_CODE_FAILED,
    error
});

export const deleteCodeAction = (data) => (
    {
        type: MastersActionTypes.DELETE_CODE_REQUEST,
        data
    });

export const deleteCodeSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_CODE_SUCCESS,
        data
    };
};

export const deleteCodeFailure = (error) => ({
    type: MastersActionTypes.DELETE_CODE_FAILED,
    error
});

export const getVehicleCountryGroupMappingAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST,
        data
    });

export const getVehicleCountryGroupMappingSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS,
        data
    };
};

export const getVehicleCountryGroupMappingFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_VEHICLE_COUNTRYGROUP_MAPPING_FAILED,
    error
});

export const insertOrUpdateVehicleCountryGroupMappingAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST,
        data
    });

export const insertOrUpdateVehicleCountryGroupMappingSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS,
        data
    };
};

export const insertOrUpdateVehicleCountryGroupMappingFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_VEHICLE_COUNTRYGROUP_MAPPING_FAILED,
    error
});

export const deleteVehicleCountryGroupMappingAction = (data) => (
    {
        type: MastersActionTypes.DELETE_VEHICLE_COUNTRYGROUP_MAPPING_REQUEST,
        data
    });

export const deleteVehicleCountryGroupMappingSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_VEHICLE_COUNTRYGROUP_MAPPING_SUCCESS,
        data
    };
};

export const deleteVehicleCountryGroupMappingFailure = (error) => ({
    type: MastersActionTypes.DELETE_VEHICLE_COUNTRYGROUP_MAPPING_FAILED,
    error
});

export const insertOrUpdatePackageMasterAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_MASTER_REQUEST,
        data
    });

export const insertOrUpdatePackageMasterSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_MASTER_SUCCESS,
        data
    };
};

export const insertOrUpdatePackageMasterFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_PACKAGE_MASTER_FAILED,
    error
});

export const deletePackageMasterAction = (data) => (
    {
        type: MastersActionTypes.DELETE_PACKAGE_MASTER_REQUEST,
        data
    });

export const deletePackageMasterSuccess = (data) => {
    return {
        type: MastersActionTypes.DELETE_PACKAGE_MASTER_SUCCESS,
        data
    };
};

export const deletePackageMasterFailure = (error) => ({
    type: MastersActionTypes.DELETE_PACKAGE_MASTER_FAILED,
    error
});

export const getAllVehicleTypeDropdownAction = (data) => (
    
    {
        type: MastersActionTypes.GET_ALL_VEHICLE_TYPE_DROPDOWN_REQUEST,
        data
    });

export const getAllVehicleTypeDropdownSuccess = (data) => {    
    return {
        type: MastersActionTypes.GET_ALL_VEHICLE_TYPE_DROPDOWN_SUCCESS,
        data
    };
};

export const getAllVehicleTypeDropdownFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_VEHICLE_TYPE_DROPDOWN_FAILED,
    error
});

export const getAllCodeRequestAction = (data) => (
    {
        type: MastersActionTypes.GET_ALL_CODE_REQUEST_REQUEST,
        data
    });

export const getAllCodeRequestSuccess = (data) => {
    return {
        type: MastersActionTypes.GET_ALL_CODE_REQUEST_SUCCESS,
        data
    };
};

export const getAllCodeRequestFailure = (error) => ({
    type: MastersActionTypes.GET_ALL_CODE_REQUEST_FAILED,
    error
});

export const insertOrUpdateCodeRequestAction = (data) => (
    {
        type: MastersActionTypes.INSERT_OR_UPDATE_CODE_REQUEST_REQUEST,
        data
    });

export const insertOrUpdateCodeRequestSuccess = (data) => {
    return {
        type: MastersActionTypes.INSERT_OR_UPDATE_CODE_REQUEST_SUCCESS,
        data
    };
};

export const insertOrUpdateCodeRequestFailure = (error) => ({
    type: MastersActionTypes.INSERT_OR_UPDATE_CODE_REQUEST_FAILED,
    error
});

export const rejectCodeRequestAction = (data) => (
    {
        type: MastersActionTypes.REJECT_CODE_REQUEST_REQUEST,
        data
    });

export const rejectCodeRequestSuccess = (data) => {
    return {
        type: MastersActionTypes.REJECT_CODE_REQUEST_SUCCESS,
        data
    };
};

export const rejectCodeRequestFailure = (error) => ({
    type: MastersActionTypes.REJECT_CODE_REQUEST_FAILED,
    error
});

export const getCodeGroupByVehicleGroupAction = (data) => (
    
    {
        type: MastersActionTypes.GET_CODE_GROUP_BY_VEHICLE_GROUP_REQUEST,
        data
    });

export const getCodeGroupByVehicleGroupSuccess = (data) => {    
    return {
        type: MastersActionTypes.GET_CODE_GROUP_BY_VEHICLE_GROUP_SUCCESS,
        data
    };
};

export const getCodeGroupByVehicleGroupFailure = (error) => ({
    type: MastersActionTypes.GET_CODE_GROUP_BY_VEHICLE_GROUP_FAILED,
    error
});
