import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import Select from "react-select";
import { connect } from 'react-redux';
import { Card, CardBody, Col, Container, Row, CardHeader, Label, Button } from 'reactstrap';
import { Pagination } from '@mui/material';
import Loader from '../Loader/Loader';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from '../DeleteModel/DeleteModel';
import { getAllTonnageAction, getAllVehicleCategoryDropDownAction, getVehicleGroupByVehicleCategoryAction, insertOrUpdateTonnageAction, deleteTonnageAction } from '../../store/actions';

const Tonnage = (props: any) => {
    document.title = "Tonnage";
    const { getAllTonnage, getAllVehicleCategoryDropDown, getVehicleGroupByVehicleCategory, insertOrUpdateTonnage, deleteTonnage, getLoginData, loading } = props;
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});
    const [openModel, setOpenModel] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [todo, setTodo] = useState(null);
    const [getAllTonnages, setGetAllTonnages] = useState<any>(null);
    const [editData, setEditData] = useState<any>({});
    const [sort, setSort] = useState("");
    const [currentPage, setCurrentPage] = useState<any>(1);
    const [searchKeyword, setSearchKeyword] = useState("");
    const [totalCount, setTotalCount] = useState<any>("");
    const [vehicleGroupList, setVehicleGroupList] = useState<any>("");
    const [selectedVehicleCategory, setSelectedVehicleCategory] = useState(null);
    const [selectedVehicleGroup, setSelectedVehicleGroup] = useState(null);
    const [vehicleCategoryDropDownData, setVehicleCategoryDropDownData] = useState<any>(null);
    const [vehicleGroupByCategoryDropDownData, setVehicleGroupByCategoryDropDownData] = useState<any>(null);
    const [vehicleCategoryId, setVehicleCategoryId] = useState<any>("");
    const [editId, seteditId] = useState<any>("");
    const [addBtn, setAddBtn] = useState<any>(false);
    const recordsPerPage = 10;
    const totalPages = Math.ceil(Number(totalCount) / recordsPerPage);
    const LoginRoleData = sessionStorage.getItem("loginRoleAccess")
    const RoleResponse = JSON.parse(LoginRoleData);
    const Role = RoleResponse?.find(data => data?.name === "Master")?.subModules?.find(val => val?.submenuName === "Tonnage")?.access

    function handleVehicleCategory(selectedVehicleCategory: any) {
        setVehicleCategoryId(selectedVehicleCategory?.id)
        setSelectedVehicleCategory(selectedVehicleCategory);
        setSelectedVehicleGroup(null);
        props.getVehicleGroupByVehicleCategoryAction(selectedVehicleCategory?.value);
    }
    function handleVehicleGroup(selectedVehicleGroup: any) {
        setSelectedVehicleGroup(selectedVehicleGroup);
        setVehicleGroupList(selectedVehicleGroup?.id)
    }

    const VehicleCategory = vehicleCategoryDropDownData?.map((item: any) => (
        {
            label: item?.value,
            value: item?.value,
            id: item?.id,
            isSelected: item?.isSelected
        }
    )
    ) || [];

    const VehicleGroup = vehicleGroupByCategoryDropDownData?.map((item: any) => (
        {
            label: item?.value,
            value: item?.value,
            id: item?.id,
            isSelected: item?.isSelected

        }
    )
    ) || [];

    const handleEdit = (tonnage: any) => {
        setAddBtn(true);
        setOpenModel(true);
        setEditData(tonnage);
        setVehicleCategoryId(tonnage?.vehicleCategoryId);
        setSelectedVehicleCategory({
            id: tonnage?.vehicleCategoryId,
            label: tonnage?.vehicleCategoryValue,
            value: tonnage?.vehicleCategoryValue,
            isSelected: tonnage?.isActive,
        })

        setVehicleGroupList(tonnage?.vehicleGroupId);
        setSelectedVehicleGroup({
            id: tonnage?.vehicleGroupId,
            label: tonnage?.vehicleGroupValue,
            value: tonnage?.vehicleGroupValue,
            isSelected: tonnage?.isActive,
        })
        setFormValiodation({});
        seteditId(tonnage?.id);
        props.getAllVehicleCategoryDropDownAction(tonnage?.vehicleCategoryId)
        props.getVehicleGroupByVehicleCategoryAction(tonnage?.vehicleCategoryValue);
    }

    const handleAdd = () => {
        setOpenModel(true);
        setAddBtn(true);
    }

    const handleSave = () => {
        if (!editData.name) {
            errors.name = 'Please fill the tonnage field'
        }
        if (!vehicleCategoryId) {
            errors.vehicleCategoryId = 'Please select vehicle category'
        }
        if (!vehicleGroupList) {
            errors.vehicleGroupId = 'Please select vehicle group'
        }
        setFormValiodation(errors);
        const tonnage = {
            id: editId ? editId : 0,
            name: editData.name,
            vehicleCategoryId: vehicleCategoryId,
        };

        if (editData.name && vehicleCategoryId && vehicleGroupList) {
            props.insertOrUpdateTonnageAction(tonnage)
            setOpenModel(false);
            handleClear();
        }
    }

    const handleDeleteTodo = () => {
        setDeleteModal(false);
        props.deleteTonnageAction(todo);
        setCurrentPage(1)
    };

    const onClickTodoDelete = (todo) => {
        setTodo(todo);
        setDeleteModal(true);
    };

    const onSortChange = (keys: any) => {
        const newSortOrder = sort === 'asc' ? 'desc' : 'asc';
        setSort(newSortOrder);
        props.getAllTonnageAction({ page: currentPage, search: searchKeyword, sort: newSortOrder, OrderByColumnName: keys, SortDirection: newSortOrder });
    };

    const onClickPagination = (event, value) => {
        setCurrentPage(value);
    };

    const handleClear = () => {
        setEditData({});
        setSelectedVehicleCategory(null)
        setVehicleGroupList("")
        setSelectedVehicleGroup(null)
        setVehicleCategoryId('');
        setFormValiodation({});
    }

    const handleCancle = () => {
        handleClear();
        seteditId("");
        setOpenModel(false);
        setAddBtn(false);
    }

    const onSearchChange = (event) => {
        setSearchKeyword(event.target.value);
        props.getAllTonnageAction({ page: "1", search: event.target.value, sort: sort })
        setCurrentPage(1);
    };

    useEffect(() => {
        props.getAllTonnageAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
        setTotalCount(getAllTonnage?.totalRowsCount);
    }, [currentPage, deleteTonnage])

    useEffect(() => {
        if (insertOrUpdateTonnage?.httpStatusCode === 200) {
            seteditId("")
            setAddBtn(false);
            setOpenModel(false);
            handleClear();
            props.getAllTonnageAction({ page: currentPage, search: searchKeyword, sort: sort, OrderByColumnName: "" });
            setTotalCount(getAllTonnage?.totalRowsCount);
        }
    }, [insertOrUpdateTonnage]);

    useEffect(() => {
        setTotalCount(getAllTonnage?.totalRowsCount);
    }, [getAllTonnage, insertOrUpdateTonnage])

    useEffect(() => {
        setGetAllTonnages(getAllTonnage)
    }, [getAllTonnage])

    useEffect(() => {
        props.getAllVehicleCategoryDropDownAction();
    }, [])

    useEffect(() => {
        setVehicleCategoryDropDownData(getAllVehicleCategoryDropDown?.data)
    }, [getAllVehicleCategoryDropDown])

    useEffect(() => {
        setVehicleGroupByCategoryDropDownData(getVehicleGroupByVehicleCategory?.data)
    }, [getVehicleGroupByVehicleCategory])

    return (
        <React.Fragment>
            {loading && <Loader />}
            {loading && <Loader />}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDeleteTodo()}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Tonnage" pageTitle="Masters" />
                    {openModel &&
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardHeader className='card-bg'><h4 className="card-title mb-0 ">Add Tonnage</h4> </CardHeader>
                                    <CardBody>
                                        <div>
                                            <div>
                                                <Row>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Tonnage</Label>
                                                            <Input type="text" className="form-control" id="placeholderInput" placeholder="Add Description" value={editData.name ? editData.name : ""} onChange={(e) => setEditData({ ...editData, name: e.target.value })} />
                                                            <span className='text-validation'>{formValidation.name}</span>
                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Category</Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedVehicleCategory}
                                                                onChange={(e) => {
                                                                    handleVehicleCategory(e);
                                                                }}
                                                                options={VehicleCategory}
                                                            />
                                                            <span className='text-validation'>{formValidation.vehicleCategoryId}</span>

                                                        </div>
                                                    </Col>
                                                    <Col lg={4} md={6}>
                                                        <div className="mb-3">
                                                            <Label htmlFor="choices-single-no-search" className="form-label text-muted">Vehicle Group </Label>
                                                            <Select
                                                                isClearable={true}
                                                                value={selectedVehicleGroup}
                                                                onChange={(e) => {
                                                                    handleVehicleGroup(e);
                                                                }}
                                                                options={VehicleGroup}
                                                            />
                                                            <span className='text-validation'>{formValidation.vehicleGroupId}</span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </CardBody>
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' onClick={() => handleSave()}>Save</Button>
                                        <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' onClick={() => handleClear()}> Clear </Button>
                                        <Button className="btn btn-primary mb-2 btn-custom-blue" type="button" color='light' onClick={handleCancle}>Cancel</Button>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex card-bg">
                                    <h4 className="card-title mb-0 flex-grow-1">Tonnage List</h4>
                                    <div className="flex-shrink-0">
                                        <div className="form-check form-switch form-switch-right form-switch-md">
                                            {Role && Role?.createAccess && <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                {!addBtn && <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={() => handleAdd()}>+ Add</Button>}
                                            </div>}
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-sm-end">
                                        <div className="search-box ms-2">
                                            <input type="text" className="form-control search" placeholder="Search..." onChange={onSearchChange} />
                                            <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className='card-padding'>
                                    <div className="live-preview">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle table-nowrap table-striped mb-0">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col">S.No </th>
                                                        <th scope="col">Vehicle Group<i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleGroupValue')}></i></th>
                                                        <th scope="col">vehicle Category<i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('vehicleCategoryValue')}></i> </th>
                                                        <th scope="col">Tonnages <i className=" bx bx-sort me-3" style={{ color: "#182b45" }} onClick={() => onSortChange('name')}></i></th>
                                                        {(Role && Role?.editAccess || Role && Role?.deleteAccess) && <th scope="col" style={{ width: 50 }}>Action</th>}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {getAllTonnages?.dataRows && getAllTonnages?.dataRows?.length > 0 ? (
                                                        getAllTonnages?.dataRows?.map((tonnage: any, index: number) => (
                                                            <tr key={tonnage?.id}>
                                                                <td>{(currentPage - 1) * recordsPerPage + index + 1}</td>
                                                                <td>{tonnage?.vehicleGroupValue}</td>
                                                                <td>{tonnage?.vehicleCategoryValue}</td>
                                                                <td>{tonnage?.name}</td>
                                                                {(Role && Role?.editAccess || Role && Role?.deleteAccess) &&
                                                                    <td>
                                                                        <div className="hstack gap-2">
                                                                            {Role && Role?.editAccess &&
                                                                                <button className="btn btn-sm btn-soft-info edit-list" onClick={() => handleEdit(tonnage)}>
                                                                                    <i className="ri-pencil-fill align-bottom" />
                                                                                </button>}
                                                                            {Role && Role?.deleteAccess &&
                                                                                <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onClickTodoDelete(tonnage?.id)}>
                                                                                    <i className="ri-delete-bin-5-fill align-bottom" />
                                                                                </button>}
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td className='no-data-foun' colSpan={10} >No data found</td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup" style={{ "height": "275px" }}>
                                            <code>
                                            </code>
                                        </pre>
                                    </div>
                                    {totalCount > 10 &&
                                        <Row className="g-4 mt-3">
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <Pagination count={totalPages} page={currentPage} onChange={onClickPagination} shape="rounded" />

                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    getAllTonnage: state.MasterReducer.getAllTonnage,
    getAllVehicleCategoryDropDown: state.MasterReducer.getAllVehicleCategoryDropDown,
    getVehicleGroupByVehicleCategory: state.MasterReducer.getVehicleGroupByVehicleCategory,
    insertOrUpdateTonnage: state.MasterReducer.insertOrUpdateTonnage,
    getLoginData: state.AuthenticationReducer?.getLoginData?.data?.userRoleAccessList,
    deleteTonnage: state.MasterReducer.deleteTonnage,

    loading: state.MasterReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    getAllTonnageAction: (data: any) => {
        dispatch(getAllTonnageAction(data))
    },

    getAllVehicleCategoryDropDownAction: (data: any) => {
        dispatch(getAllVehicleCategoryDropDownAction(data))
    },

    getVehicleGroupByVehicleCategoryAction: (data: any) => {
        dispatch(getVehicleGroupByVehicleCategoryAction(data))
    },
    insertOrUpdateTonnageAction: (data: any) => {
        dispatch(insertOrUpdateTonnageAction(data))
    },
    deleteTonnageAction: (data: any) => {
        dispatch(deleteTonnageAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(Tonnage);
