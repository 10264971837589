import React, { useEffect, useState } from 'react';
import {
    Input, Button, ButtonGroup, UncontrolledButtonDropdown,
    DropdownToggle, DropdownMenu, DropdownItem, CardBody,
    Col, Container, Row, CardHeader, Label, Card, Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from "classnames";
import { connect } from "react-redux";
import Select from "react-select";
import Loader from '../Loader/Loader';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { getModelAction, getCountryAction, bindCodeBOMAction, updateCodelistCodeBOMAction, initCodeConfigurationModule, codeConfiguratiorExcelExportAction, getConfiguratorVehicleGroupAction } from '../../store/actions';

const CodeConfiguration = (props) => {
    document.title = "Code Cofiguration";
    const {bindCodeBOM, updateCodelistCodeBOM, codeConfiguratiorExcelExport, getConfiguratorVehicleGroup, loading, resBindCodeBOM } = props;
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});
    const [selectedModels, setSelectedModels] = useState<any>('');
    const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<any>('');
    const [selectedCountry, setSelectedCountry] = useState<any>('');
    const [countryList, setCountryList] = useState<any>({});
    const [varientList, setVarientList] = useState<any>({});
    const [varientHeaderList, setVarientHeaderList] = useState<any>({});
    const [vehicleGroupList, setVehicleGroupList] = useState<any>("");
    const [bindBomListData, setBindBomListData] = useState<any>(null);
    const [file, setFile] = useState<any>('');
    const [excelUrl, setExcelUrl] = useState<any>([]);
    const [countryGroupList, setCountryGroupList] = useState<any>(null);
    const [gropDownVehicleList, setGropDownVehicleList] = useState<any>(null);
    const [dropDownmodeList, setDropDownModelList] = useState<any>(null);

    const Vehicle = gropDownVehicleList?.map((item) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const Models = dropDownmodeList?.map((item) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const Country = countryGroupList?.map((item) => (
        {
            label: item.value,
            value: item.value,
            id: item.id,
            isSelected: item.isSelected
        }
    )
    ) || [];

    const [navBadgeTab, setnavBadgeTab] = useState<any>("1");

    const navBadgeToggle = (val, tab) => {
        if (navBadgeTab !== tab) {
            setnavBadgeTab(tab);
        }
        let stringModelData: any = JSON.stringify([val])
        let formData = new FormData();
        formData.append('file', file);
        formData.append('vehicleGroupId', vehicleGroupList);
        formData.append('countryList', countryList);
        formData.append('varientList', stringModelData);
        formData.append('Header', varientHeaderList);
        formData.append('Key', "Varients");
        if (selectedModels?.length > 0 && selectedCountry?.length > 0 && file) {
            props.bindCodeBOMAction(formData);
        }
    };

    const handleModels = (selectedOptions) => {
        setSelectedModels(selectedOptions);
        const transformedHeaderData = selectedOptions.map(option => ({
            id: option.id,
            varient: option.value
        }));
        const transformedData = selectedOptions.map(option => option ? option?.id : null);
        const stringModelData = JSON.stringify(transformedData);
        const stringModelHeaderData = JSON.stringify(transformedHeaderData)
        setVarientList(stringModelData);
        setVarientHeaderList(stringModelHeaderData)
    };

    const handleVehicleGroup = (selectedOptions) => {
        setSelectedVehicleGroup(selectedOptions);
        props.getConfiguratorVehicleGroupAction(selectedOptions?.id)
        setVehicleGroupList(selectedOptions?.id);
        setSelectedModels("");
        setVarientList({})

    };
    const handleCountry = (selectedCountry) => {
        setSelectedCountry(selectedCountry);
        const transformedData = selectedCountry.map(option => option ? option?.id : null);
        const stringCountryData = JSON.stringify(transformedData);
        setCountryList(stringCountryData)
    }

    const handleFileChange = (event: any) => {
        setFile(event.target.files[0])
    };

    const handleMapFun = () => {
        // @ts-ignore
        if (!vehicleGroupList) {
            errors.vehicleGroup = 'Please select the vehicleGroup'
        }
        if (!selectedModels?.length > 0) {
            errors.models = 'Please select the models'
        }
        // @ts-ignore
        if (!selectedCountry?.length > 0) {
            errors.country = 'Please select the country'
        }
        if (!file) {
            errors.file = 'Please Insert Code BOM'
        }
        setFormValiodation(errors);
        let formData = new FormData();
        formData.append('file', file);
        formData.append('vehicleGroupId', vehicleGroupList);
        formData.append('countryList', countryList);
        formData.append('varientList', varientList);
        formData.append('Header', varientHeaderList);
        formData.append('Key', "Key");
        if (selectedModels?.length > 0 && selectedCountry?.length > 0 && vehicleGroupList && file) {
            props.bindCodeBOMAction(formData);
            setnavBadgeTab("1");
            if (navBadgeTab !== 1 || navBadgeTab == 1) {
                setnavBadgeTab(1);
            }
        }
    }

    useEffect(() => {
        if (codeConfiguratiorExcelExport.httpStatusCode == 200) {
            window.location.href = excelUrl
        }
        if (
            codeConfiguratiorExcelExport &&
            codeConfiguratiorExcelExport?.dataList &&
            codeConfiguratiorExcelExport?.dataList?.length > 0 &&
            codeConfiguratiorExcelExport?.dataList[0].getExcelFilePath
        ) {
            const excelData = codeConfiguratiorExcelExport?.dataList[0].getExcelFilePath;
            setExcelUrl(excelData);
        }
    }, [codeConfiguratiorExcelExport, excelUrl]);

    const handleExport = () => {
        const mapId = bindCodeBOM?.data?.mapId
        props.codeConfiguratiorExcelExportAction({ mapId, userInput: "CodeBom", getExcelFilePath: "" })

    }

    useEffect(() => {
        props.initCodeConfigurationModule();
        // props.getCountryAction();
        // props.getModelAction();
        props.getConfiguratorVehicleGroupAction()
    }, [])

    useEffect(() => {
        setGropDownVehicleList(getConfiguratorVehicleGroup?.data?.vehicleGroup);
        setCountryGroupList(getConfiguratorVehicleGroup?.data?.countryGroup);
        setDropDownModelList(getConfiguratorVehicleGroup?.data?.vehicle)  
    }, [getConfiguratorVehicleGroup])

    useEffect(() => {
        if (updateCodelistCodeBOM) {
            onGetBOMUpdate(updateCodelistCodeBOM)
        }
    }, [updateCodelistCodeBOM])

    useEffect(() => {
        setBindBomListData(bindCodeBOM);
    }, [bindCodeBOM, resBindCodeBOM])

    const onGetBOMUpdate = (updateCodelistCodeBOM: any) => {
        if (resBindCodeBOM && updateCodelistCodeBOM) {
            resBindCodeBOM.forEach((item) => {
                item.vehicleModelMasterModels = item.vehicleModelMasterModels.map((model) => {
                    const updatedModel = updateCodelistCodeBOM.vehicleModelMasterModels.find(
                        (filteUpdateData: any) =>
                            model.code === filteUpdateData.code &&
                            model.varient === filteUpdateData.varient
                    );
                    return updatedModel ? updatedModel : model;
                });
                if (item.code === updateCodelistCodeBOM.code) {
                    item.naValue = updateCodelistCodeBOM?.naKey === "NA" ? "true" : "false";
                }
            });
        }
    };

    const handleUpdateCodeBOMList = (vehicleModelItems, CodeDetails, key, parentIndex, childIndex) => {
        const vehicleModelMasterModels = [vehicleModelItems]
        props.updateCodelistCodeBOMAction({ codeGroup: CodeDetails?.codeGroup, mapId: CodeDetails?.mapId, codeDescription: CodeDetails?.codeDescription, code: CodeDetails?.code, remark: CodeDetails?.remark, key, vehicleModelMasterModels });
    }

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Code Configuration" pageTitle="Code Configuration" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader><h4 className="card-title mb-0">Code Configuration</h4></CardHeader>

                                <CardBody>
                                    <div>
                                        <div>
                                            <Row>
                                            <Col lg={3} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-default" className="form-label text-muted">Vehicle Group</Label>
                                                        <Select
                                                            value={selectedVehicleGroup}
                                                            onChange={(e) => {
                                                                handleVehicleGroup(e);
                                                            }}
                                                            styles={{
                                                                valueContainer: (base) => ({ ...base, maxHeight: 50, overflowY: 'auto' }),
                                                              }}
                                                            options={Vehicle}
                                                        />
                                                        <span className='text-validation'>{formValidation.vehicleGroup}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-default" className="form-label text-muted">Models</Label>
                                                        <Select
                                                            value={selectedModels}
                                                            isMulti={true}
                                                            closeMenuOnSelect={false}
                                                            onChange={(e) => {
                                                                handleModels(e);
                                                            }}
                                                            styles={{
                                                                valueContainer: (base) => ({ ...base, maxHeight: 50, overflowY: 'auto' }),
                                                              }}
                                                            options={Models}
                                                        />
                                                        <span className='text-validation'>{formValidation.models}</span>
                                                    </div>
                                                </Col>

                                                <Col lg={3} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-single-groups" className="form-label text-muted">Country</Label>
                                                        <Select
                                                            value={selectedCountry}
                                                            isMulti={true}
                                                            closeMenuOnSelect={false}
                                                            // searchable
                                                            onChange={(e) => {
                                                                handleCountry(e);
                                                            }}
                                                            styles={{
                                                                valueContainer: (base) => ({ ...base, maxHeight: 50, overflowY: 'auto' }),
                                                              }}
                                                            options={Country}
                                                        />
                                                        <span className='text-validation'>{formValidation.country}</span>
                                                    </div>
                                                </Col>
                                                <Col lg={3} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="formFile" className="form-label text-muted">
                                                            Insert Code BOM
                                                        </Label>
                                                        <div>
                                                            <Input
                                                                className="form-control"
                                                                type="file"
                                                                id="formFile"
                                                                onChange={handleFileChange}
                                                            />
                                                        </div>
                                                        <span className='text-validation'>{formValidation.file}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div>
                                            <Row>
                                                <div>
                                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                        <Button className="btn btn-primary btn-custom-blue" type="button" color='light' onClick={handleMapFun} >Map</Button>
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {bindBomListData?.data?.headers?.length > 0 &&
                        <Row>
                            <Col xl={12}>
                                <Card>
                                    <CardHeader className="align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Code Configuration</h4>
                                        <div className="flex-shrink-0">
                                            <div className="form-check form-switch form-switch-right form-switch-md">
                                                <a
                                                >
                                                    <ButtonGroup>
                                                        <UncontrolledButtonDropdown>
                                                            <Button onClick={() => handleExport()} className="btn-custom-blue">Excel Export</Button>
                                                            <DropdownToggle tag="button" className="btn btn-custom-blue" split>
                                                            </DropdownToggle>
                                                        </UncontrolledButtonDropdown>
                                                    </ButtonGroup>
                                                </a>
                                            </div>
                                        </div>
                                    </CardHeader>
                                    <Nav tabs className="nav-tabs nav-justified mb-3">
                                        {bindBomListData?.data?.headers?.map((val, index) => (
                                            <NavItem key={index}>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({ active: navBadgeTab === index + 1 })}
                                                    onClick={() => { navBadgeToggle(val?.id, index + 1); }}
                                                >
                                                    {val.varient}
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                    {/* </CardHeader> */}
                                    <CardBody className='card-padding'>
                                        {bindBomListData &&
                                            <div className="live-preview">
                                                <div className="table-responsive table-card" style={{ maxHeight: "700px" }}>
                                                    <table className="table align-middle table-striped mb-0">
                                                        <thead
                                                         className="table-light"
                                                         style={{ position: "sticky",top: "0", zIndex: "1",}} >
                                                            <tr>
                                                                <th scope="col" rowSpan={2}>Code Group</th>
                                                                <th scope="col" rowSpan={2}>Code</th>
                                                                <th scope="col" rowSpan={2}>Code Description</th>
                                                                <th scope="col" rowSpan={2}>Remarks</th>

                                                                {(bindBomListData?.data?.varientsModels?.length > 0) && (bindBomListData?.data?.varientsModels[0]?.vehicleModelMasterModels)?.map((data, i) => (
                                                                    <React.Fragment key={i} >
                                                                        <th style={{ 'color': 'red' }} scope="col" colSpan={data?.countryList.length}>{data?.varient}</th>
                                                                        <th style={{ background: "#dfdfdf" }} scope="col" colSpan={3}>Code BOM</th>
                                                                        <th style={{ background: "#dfdfdf" }} scope="col" rowSpan={2}>Result</th>
                                                                        <th style={{ background: "#dfdfdf" }} scope="col" rowSpan={2}>Remark</th>
                                                                        <th style={{ background: "#dfdfdf" }} scope="col" rowSpan={2}>User Input</th>
                                                                    </React.Fragment>
                                                                ))}
                                                            </tr>
                                                            <tr>
                                                                {(bindBomListData?.data?.varientsModels?.length > 0) && (bindBomListData?.data?.varientsModels[0]?.vehicleModelMasterModels)?.map((data, i) => (
                                                                    <React.Fragment key={i}>
                                                                        {(data?.countryList?.length > 0) && (data.countryList)?.map((countryData, countryIndex) => (
                                                                            <React.Fragment key={countryIndex}>
                                                                                <th style={{ 'color': 'red' }} scope="col">{countryData?.countryGroup}</th>
                                                                            </React.Fragment>
                                                                        ))}
                                                                        <th style={{ background: "#dfdfdf" }} className='table-width' scope="col" >Code Rule</th>
                                                                        <th style={{ background: "#dfdfdf" }} scope="col" >CG</th>
                                                                        <th style={{ background: "#dfdfdf" }} className='table-width' scope="col" >Standard Code Rule</th>
                                                                    </React.Fragment>
                                                                ))}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {bindBomListData?.data?.varientsModels?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item?.codeGroup ? item?.codeGroup : '-'}</td>
                                                                    <td>{item?.code ? item?.code : '-'}</td>
                                                                    <td>{item?.codeDescription ? item?.codeDescription : "-"}</td>
                                                                    <td>{item?.remark ? item?.remark : "-"}</td>
                                                                    {
                                                                        item?.vehicleModelMasterModels?.map((vehicleModelItems, vehicleModelIndex) => (
                                                                            <React.Fragment key={vehicleModelIndex}>
                                                                                {vehicleModelItems?.countryList?.map((countryListItem, countryListIndex) => (
                                                                                    <React.Fragment key={countryListIndex}>
                                                                                        <td>{countryListItem.option ? countryListItem.option : '-'}</td>
                                                                                    </React.Fragment>
                                                                                ))}
                                                                                {(vehicleModelItems?.codeBOMModels && vehicleModelItems?.codeBOMModels.length) ? vehicleModelItems?.codeBOMModels?.map((codeBOMModelsItem, codeBOMModelsIndex) => (
                                                                                    <React.Fragment key={codeBOMModelsIndex}>
                                                                                        <td style={{ background: "#e9ebec" }} className='table-width'>{codeBOMModelsItem?.coderule ? codeBOMModelsItem?.coderule : '-'}</td>
                                                                                        <td style={{ background: "#e9ebec" }}>{codeBOMModelsItem?.cGorBG ? codeBOMModelsItem?.cGorBG : '-'}</td>
                                                                                        <td style={{ background: "#e9ebec" }} className='table-width'>{codeBOMModelsItem?.standerdCodeRule ? codeBOMModelsItem?.standerdCodeRule : '-'}</td>
                                                                                        <td
                                                                                            style={{
                                                                                                color: (() => {
                                                                                                    if (codeBOMModelsItem?.result === "OK") {
                                                                                                        if (item?.naValue === "true") {
                                                                                                            return '#de7d45';
                                                                                                        } else if (item?.naValue === "false") {
                                                                                                            return "green";
                                                                                                        } else {
                                                                                                            return "green"; 
                                                                                                        }
                                                                                                    }
                                                                                                    return 'red';
                                                                                                })(),
                                                                                                background: "#e9ebec"
                                                                                            }}
                                                                                        >
                                                                                            {codeBOMModelsItem?.result ? codeBOMModelsItem.result : '-'}
                                                                                        </td>
                                                                                        <td style={{ background: "#e9ebec" }}>{codeBOMModelsItem?.remarks ? codeBOMModelsItem?.remarks : '-'}</td>
                                                                                        <td style={{ background: "#e9ebec" }} className="center-content">
                                                                                            {codeBOMModelsItem?.result === "NOK" ? (
                                                                                                <ButtonGroup>
                                                                                                    <UncontrolledButtonDropdown>
                                                                                                        <DropdownToggle tag="button" className="btn btn-light" split>
                                                                                                        </DropdownToggle>
                                                                                                        <DropdownMenu>
                                                                                                            <DropdownItem onClick={() => handleUpdateCodeBOMList(vehicleModelItems, item, "UCL", index, vehicleModelIndex)}>
                                                                                                                Update Code List
                                                                                                            </DropdownItem>
                                                                                                            <DropdownItem onClick={() => handleUpdateCodeBOMList(vehicleModelItems, item, "UCB", index, vehicleModelIndex)}>
                                                                                                                Update Code BOM
                                                                                                            </DropdownItem>
                                                                                                        </DropdownMenu>
                                                                                                    </UncontrolledButtonDropdown>
                                                                                                </ButtonGroup>
                                                                                            ) : (
                                                                                                "NA"
                                                                                            )}
                                                                                        </td>
                                                                                    </React.Fragment>
                                                                                )) :
                                                                                    <React.Fragment>
                                                                                        <td style={{ background: "#e9ebec" }}>-</td>
                                                                                        <td style={{ background: "#e9ebec" }}>-</td>
                                                                                        <td style={{ background: "#e9ebec" }}>-</td>
                                                                                        <td style={{ background: "#e9ebec" }}>-</td>
                                                                                        <td style={{ background: "#e9ebec" }}>-</td>
                                                                                        <td style={{ background: "#e9ebec" }}>-</td>
                                                                                    </React.Fragment>
                                                                                }
                                                                            </React.Fragment>
                                                                        ))
                                                                    }
                                                                </tr>
                                                            ))}
                                                            <React.Fragment>
                                                            </React.Fragment>
                                                        </tbody>

                                                    </table>

                                                </div>
                                            </div>}

                                        <div className="d-none code-view">
                                            <pre className="language-markup" style={{ "height": "275px" }}>
                                                <code>
                                                </code>
                                            </pre>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    bindCodeBOM: state.CodeConfigurationModelReducer.bindCodeBOM,
    resBindCodeBOM: state.CodeConfigurationModelReducer.bindCodeBOM.data?.varientsModels,
    getConfiguratorVehicleGroup: state.CodeConfigurationModelReducer.getConfiguratorVehicleGroup,
    updateCodelistCodeBOM: state.CodeConfigurationModelReducer.updateCodelistCodeBOM?.data,
    codeConfiguratiorExcelExport: state.CodeConfigurationModelReducer.codeConfiguratiorExcelExport,
    loading: state.CodeConfigurationModelReducer.loading
})

const mapDispatchToProps = (dispatch) => ({
    getModelAction: (data) => {
        dispatch(getModelAction(data))
    },
    getCountryAction: (data) => {
        dispatch(getCountryAction(data))
    },
    bindCodeBOMAction: (data) => {
        dispatch(bindCodeBOMAction(data))
    },
    updateCodelistCodeBOMAction: (data) => {
        dispatch(updateCodelistCodeBOMAction(data))
    },
    codeConfiguratiorExcelExportAction: (data) => {
        dispatch(codeConfiguratiorExcelExportAction(data))
    },
    getConfiguratorVehicleGroupAction: (data) => {
        dispatch(getConfiguratorVehicleGroupAction(data))
    },
    initCodeConfigurationModule: (data) => {
        dispatch(initCodeConfigurationModule(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(CodeConfiguration);
