import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { loginsAction } from '../../store/actions';

//redux
import { useSelector, useDispatch } from "react-redux";

// import { Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin, resetLoginFlag } from "../../store/actions";

import logoLight from "../../assets/images/logo-light.png";

import withRouter from '../../Components/Common/withRouter';
import { createSelector } from 'reselect';
import { Link, useNavigate } from "react-router-dom";
import Loader from '../Loader/Loader';

const Login = (props: any) => {
    const { getLoginData, loader } = props;
    const history = useNavigate();
    const dispatch = useDispatch();
    const selectLayoutState = (state) => state.Account;
    const selectLayoutProperties = createSelector(
        selectLayoutState,
        (layout) => ({
            user: layout.user,
            errorMsg: layout.errorMsg,
            loading: layout.loading,
            error: layout.error,
        })
    );
    // Inside your component
    const {
        user,
        errorMsg,
        loading,
        error
    } = useSelector(selectLayoutProperties);
    
    const [userLogin, setUserLogin] = useState<any>([]);
    const [passwordShow, setPasswordShow] = useState(false);
    const [loginData, setLoginData] = useState<any>({});
    const errors: any = {}
    const [formValidation, setFormValiodation] = useState<any>({});

    // useEffect(() => {
    //     if (user && user) {
    //         const updatedUserData = process.env.REACT_APP_DEFAULTAUTH === "firebase" ? user.multiFactor.user.email : user.user.email;
    //         setUserLogin({
    //             email: updatedUserData,
    //             password: user.user.confirm_password ? user.user.confirm_password : ""
    //         });
    //     }
    // }, [user]);

    // useEffect(() => {
    //     const token = JSON.parse(JSON.stringify(sessionStorage.getItem("authToken"))); 
    //     if(token) {
    //         history("/code-list");
    //     }
    // },[])

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: userLogin.email || "admin@themesbrand.com" || '',
            password: userLogin.password || "123456" || '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, props.router.navigate));
        }
    });

    const signIn = type => {
        dispatch(socialLogin(type, props.router.navigate));
    };

    //handleTwitterLoginResponse
    // const twitterResponse = e => {}

    //for facebook and google authentication
    const socialResponse = type => {
        signIn(type);
    };

    // useEffect(() => {
    //     if (error) {
    //         setTimeout(() => {
    //             dispatch(resetLoginFlag());
    //         }, 3000);
    //     }
    // }, [dispatch, error]);


    document.title = "D-Code";

    const onOpenSign = () => {
        if (!loginData.emailId) {
            errors.emailId = 'Please fill the field'
        }
        if (!loginData.password) {
            errors.password = 'Please fill the field'
        }
        setFormValiodation(errors);
        // history("/code-list");
        const data = {
            emailId: loginData.emailId,
            password: loginData.password,
            returnURL: ""
        };
        if (loginData.emailId && loginData.password ) { 
            props.loginsAction(data)
         }
    };

    useEffect(() => {
        if (getLoginData?.payload?.httpStatusCode === 200) {
            const codeBuilderAccess = getLoginData?.data?.userRoleAccessList?.find(role => role.name === "CodeBuilder")?.hasAccess;
            const codeListAccess = getLoginData?.data?.userRoleAccessList?.find(role => role.name === "CodeList")?.hasAccess;
            if (codeListAccess) {
                history("/code-list");
            } else if (codeBuilderAccess) {
                history("/boolean-logic-creation");
            } else {
                history("/code-configuration");
            }
            sessionStorage.setItem('loginRoleAccess', JSON.stringify(getLoginData?.data?.userRoleAccessList));
            sessionStorage.setItem('authToken', getLoginData?.data?.jwt);
            sessionStorage.setItem('refreshToken', getLoginData?.data?.refreshToken);
            sessionStorage.setItem('UsersName', getLoginData?.data?.userName);
            sessionStorage.setItem('RolesRes', JSON.stringify(getLoginData?.data?.userRoles));
        }
    }, [getLoginData])

    return (
        <React.Fragment>
              {loader && <Loader />}
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            {/* <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="20" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Premium Admin & Dashboard Template</p>
                                </div>
                            </Col> */}
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue</p>
                                        </div>
                                        {/* {errorMsg && errorMsg ? (<Alert color="danger"> {errorMsg} </Alert>) : null} */}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={(e) => setLoginData({ ...loginData, emailId: e.target.value })}
                                                    />
                                                    <span className='text-validation'>{formValidation.emailId}</span>
                                                </div>
                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        {/* <Link to="/forgot-password" className="text-muted">Forgot password?</Link> */}
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={(e) => setLoginData({ ...loginData, password: e.target.value })}
                                                        />
                                                            <span className='text-validation'>{formValidation.password}</span>
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                </div>

                                                {/* <div className="mt-4">
                                                    <Button color="success" disabled={error ? null : loading ? true : false} className="btn btn-success w-100" type="submit">
                                                        {error ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                        Sign In
                                                    </Button>
                                                </div> */}
                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" onClick={() => onOpenSign()}>
                                                        Sign In
                                                    </Button>
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                        <div className="signin-other-title">
                                                            <h5 className="fs-13 mb-4 title">Sign In with</h5>
                                                        </div>
                                                        <div>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-primary btn-icon me-1"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    socialResponse("facebook");
                                                                }}
                                                            >
                                                                <i className="ri-facebook-fill fs-16" />
                                                            </Link>
                                                            <Link
                                                                to="#"
                                                                className="btn btn-danger btn-icon me-1"
                                                                onClick={e => {
                                                                    e.preventDefault();
                                                                    socialResponse("google");
                                                                }}
                                                            >
                                                                <i className="ri-google-fill fs-16" />
                                                            </Link>
                                                            <Button color="dark" className="btn-icon"><i className="ri-github-fill fs-16"></i></Button>{" "}
                                                            <Button color="info" className="btn-icon"><i className="ri-twitter-fill fs-16"></i></Button>
                                                        </div>
                                                    </div> */}
                                            </Form>
                                        </div>
                                        {/* <div className="mt-4">
                                            <Button color="success" className="btn btn-success w-100" onClick={() => onOpenSign()}>
                                                Sign In
                                            </Button>
                                        </div> */}
                                    </CardBody>
                                </Card>

                                {/* <div className="mt-4 text-center">
                                    <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                                </div> */}

                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

const mapStateToProps = (state: any) => ({
    getLoginData: state.AuthenticationReducer.getLoginData,
    loader: state.AuthenticationReducer.loading
})

const mapDispatchToProps = (dispatch: any) => ({
    loginsAction: (data: any) => {
        dispatch(loginsAction(data))
    },
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
