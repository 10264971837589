import { CodeConfigurationActionTypes } from "./actionType";

const initialState = {
    isLoading: false,
    loading: false,
    items: [],
    errors: {},
    modelResponse: {},
    countryResponse: {},
    bindCodeBOM: {},
    resBindCodeBOM:{},
    updateCodelistCodeBOM: null,
    codeConfiguratiorExcelExport : {},
    getConfiguratorVehicleGroup: {}
}

const CodeConfigurationModelReducer = (state = initialState, action) => {
    const response = action.data;
    switch (action.type) {
        case CodeConfigurationActionTypes.INIT_GET_CODECONFIGURATION_DETAILS_MODULE:
            return {
                ...state, loading: false, isLoading: false, items: [], modelResponse: {}, countryResponse: {}, bindCodeBOM: {}, updateCodelistCodeBOM: null, codeConfiguratiorExcelExport: {},
            }
        case CodeConfigurationActionTypes.GET_INPUT_MODEL_REQUEST:
            return { ...state, loading: true, modelResponse: {} }
        case CodeConfigurationActionTypes.GET_INPUT_MODEL_SUCCESS:
            return { ...state, loading: false, modelResponse: response.payload }
        case CodeConfigurationActionTypes.GET_INPUT_MODEL_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeConfigurationActionTypes.GET_INPUT_COUNTRY_REQUEST:
            return { ...state, loading: true, countryResponse: {} }
        case CodeConfigurationActionTypes.GET_INPUT_COUNTRY_SUCCESS:
            return { ...state, loading: false, countryResponse: response.payload }
        case CodeConfigurationActionTypes.GET_INPUT_COUNTRY_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeConfigurationActionTypes.BING_CODE_BOM_REQUEST:
            return { ...state, loading: true, bindCodeBOM: {} }
        case CodeConfigurationActionTypes.BING_CODE_BOM_SUCCESS:
            return { ...state, loading: false, bindCodeBOM: response.payload }
        case CodeConfigurationActionTypes.BING_CODE_BOM_FAILED:
            return { ...state, loading: false, errors: action.error }

            case CodeConfigurationActionTypes.UPDATE_CODE_LIST_CODEBOM_REQUEST:
            return { ...state, loading: true, updateCodelistCodeBOM: null }
        case CodeConfigurationActionTypes.UPDATE_CODE_LIST_CODEBOM_SUCCESS:
            return { ...state, loading: false, updateCodelistCodeBOM: response.payload }
        case CodeConfigurationActionTypes.UPDATE_CODE_LIST_CODEBOM_FAILED:
            return { ...state, loading: false, errors: action.error }

        case CodeConfigurationActionTypes.CODE_CONFIGURATIOR_EXCELEXPORT_REQUEST:
            return { ...state, loading: true, codeConfiguratiorExcelExport: {} }
        case CodeConfigurationActionTypes.CODE_CONFIGURATIOR_EXCELEXPORT_SUCCESS:
            return { ...state, loading: false, codeConfiguratiorExcelExport: response.payload }
        case CodeConfigurationActionTypes.CODE_CONFIGURATIOR_EXCELEXPORT_FAILED:
            return { ...state, loading: false, errors: action.error }

            case CodeConfigurationActionTypes.GET_CONFIGURATOR_VEHICLE_GROUP_REQUEST:
            return { ...state, loading: true, getConfiguratorVehicleGroup: {} }
        case CodeConfigurationActionTypes.GET_CONFIGURATOR_VEHICLE_GROUP_SUCCESS:
            return { ...state, loading: false, getConfiguratorVehicleGroup: response.payload }
        case CodeConfigurationActionTypes.GET_CONFIGURATOR_VEHICLE_GROUP_FAILED:
            return { ...state, loading: false, errors: action.error }
        
        default: {
            return state;
        }
    }
}

export default CodeConfigurationModelReducer;
