import React, { useEffect, useState, version } from "react";
import { Link } from "react-router-dom";
import {
  CardFooter,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  getDropdownCodeListAction,
  getCodeWorkflowTrackerTableRequest,
  getSubmitCodeListAction,
} from "../../store/actions";
import "../CodeConfiguration/style.css";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
// import { StripedColumnsTables } from './BasicTablesCode';
// import dataList from "./delete.json";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Label,
  Button,
} from "reactstrap";

const CodeWorkflowTracker = (props) => {
  document.title = "Code List Workflow View";

  const { dropdownCodeList, viewTableData, loading, submitResponse,CodeWorkflowTrackerTableData } = props;
  const navigate = useNavigate();
  const [selectedVehicleGroup, setSelectedVehicleGroup] = useState<any>(null);
  const [selectedVehicleCategory, setSelectedVehicleCategory] = useState([]);
  const [selectedTonnage, setSelectedTonnage] = useState([]);
  const [selectedVehicleModel, setSelectedVehicleModel] = useState([]);
  const [selectedMarketCluster, setSelectedMarketCluster] = useState([]);
  const [selectedCodeGroup, setSelectedCodeGroup] = useState([]);
  const [selectedCode, setSelectedCode] = useState([]);
  const [showTableList, setShowTableList] = useState(false);
  const [editTableLists, setEditTableList] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [codeListLable, setCodeListLable] = useState("View");
  const [selectedVehicles, setSelectedVehicles] = useState<any>([]);
  const animatedComponents = makeAnimated();
  const errors: { [key: string]: string } = {};
  const [modal_center, setmodal_center] = useState<boolean>(false);
  const [gotoCreatorRequest, setGotoCreatorRequest] = useState<boolean>(false);
  const [formValidation, setFormValiodation] = useState<{
    [key: string]: string;
  }>({});
  const LoginRoleData: any = sessionStorage.getItem("loginRoleAccess")
  const RoleResponse = JSON.parse(LoginRoleData);
  const Role = RoleResponse?.find(data => data?.name === "CodeList")?.subModules?.find(val => val?.submenuName === "CreatorRequest")?.access

  const [formState, setFormState] = useState({
    VehicleGroupId: 0,
    VehicleCategory: [],
    Tonnage: [],
    VehicleModel: [],
    MarketCluster: [],
    CodeGroup: [],
    Code: [],
  });

  const [dropDownOptions, setDropDownOptions] = useState<any>({
    vehicleGroup: [],
    vehicleCategory: [],
    tonnage: [],
    vehicalModel: [],
    marketCluster: [],
    codeGroup: [],
    code: [],
  });

  const [dataList, setDataList] = useState<any>({
    version: "",
    codeListElement: [],
  });

  useEffect(() => {
    props.getDropdownCodeListAction(formState);
  }, [formState]);

  //   useEffect(() => {
  //     const token = JSON.parse(JSON.stringify(sessionStorage.getItem("authToken"))); 
  //     if(!token) {
  //       navigate("/");
  //     }
  // },[])

  useEffect(() => {
    if (dropdownCodeList && Object.keys(dropdownCodeList).length !== 0) {
      LoadDropDownList();
    }
  }, [dropdownCodeList]);

  const LoadDropDownList = () => {
    const vehicleGroupOptions = dropdownCodeList.vehicleGroup.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));
    const vehicleCategoryOptions = dropdownCodeList.vehicleCategory.map(
      (option) => ({
        value: option.value,
        label: option.value,
        id: option.id,
      })
    );
    const tonnageOptions = dropdownCodeList.tonnage.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));
    const vehicalModelOptions = dropdownCodeList.vehicalModel.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));
    const marketClusterOptions = dropdownCodeList.marketCluster.map(
      (option) => ({
        value: option.value,
        label: option.value,
        id: option.id,
      })
    );
    const codeGroupOptions = dropdownCodeList.codeGroup.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));
    const codeOptions = dropdownCodeList.code.map((option) => ({
      value: option.value,
      label: option.value,
      id: option.id,
    }));

    setDropDownOptions({
      vehicleGroup: vehicleGroupOptions,
      vehicleCategory: vehicleCategoryOptions,
      tonnage: tonnageOptions,
      vehicalModel: vehicalModelOptions,
      marketCluster: marketClusterOptions,
      codeGroup: codeGroupOptions,
      code: codeOptions,
    });
  };

  const handleDropdownChange = (selectedOption: any, field: string) => {
    const updatedFormState = { ...formState };

    switch (field) {
      case "VehicleGroupId":
        updatedFormState.VehicleGroupId = selectedOption
          ? selectedOption.id
          : 0;
        setSelectedVehicleGroup(selectedOption);
        setSelectedVehicleCategory([]);
        setSelectedTonnage([]);
        setSelectedVehicleModel([]);
        setSelectedCodeGroup([]);
        setSelectedCode([]);
        break;
      case "VehicleCategory":
        updatedFormState.VehicleCategory = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        setSelectedVehicleCategory(selectedOption);
        setSelectedTonnage([]);
        setSelectedVehicleModel([]);
        break;
      case "Tonnage":
        updatedFormState.Tonnage = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        setSelectedTonnage(selectedOption);
        setSelectedVehicleModel([]);
        break;
      case "VehicleModel":
        updatedFormState.VehicleModel = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        setSelectedVehicleModel(selectedOption);
        break;
      case "MarketCluster":
        updatedFormState.MarketCluster = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        setSelectedMarketCluster(selectedOption);
        break;
      case "CodeGroup":
        updatedFormState.CodeGroup = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        setSelectedCodeGroup(selectedOption);
        setSelectedCode([]);
        break;
      case "Code":
        updatedFormState.Code = selectedOption
          ? selectedOption.map((opt) => opt.id)
          : [];
        setSelectedCode(selectedOption);
        break;
      default:
        break;
    }

    setFormState(updatedFormState);
    // console.log('Updated formState:', updatedFormState);
  };

  const handleViewCodeList = () => {
    if (formState.VehicleGroupId === 0) {
      errors.VehicleGroupId = "Please select the Vehicle Group";
    }
    if (formState.VehicleCategory.length === 0) {
      errors.VehicleCategory = "Please select the Vehicle Category";
    }
    setFormValiodation(errors);
    setSelectedVehicles([]);
    if (Object.keys(errors).length === 0) {
      props.getCodeWorkflowTrackerTableRequest(formState);
      setShowTableList(true);
    }
  };

  useEffect(() => {
    // console.log("viewTableData", viewTableData);
    if (CodeWorkflowTrackerTableData && Object.keys(CodeWorkflowTrackerTableData).length !== 0) {
      setDataList(CodeWorkflowTrackerTableData);
    }
  }, [CodeWorkflowTrackerTableData]);

  function tog_center() {
    setmodal_center(!modal_center);
  }

  const handleClearDropdown = () => {
    setSelectedVehicleGroup(null);
    setSelectedVehicleCategory([]);
    setSelectedTonnage([]);
    setSelectedVehicleModel([]);
    setSelectedMarketCluster([]);
    setSelectedCodeGroup([]);
    setSelectedCode([]);
    setShowTableList(false);
    setFormState({
      ...formState,
      VehicleGroupId: 0,
      VehicleCategory: [],
      Tonnage: [],
      VehicleModel: [],
      MarketCluster: [],
      CodeGroup: [],
      Code: [],
    });
  };

  const staticHeader = [
    "Code Group",
    "Code",
    "Code Description",
    "Note",
    "Is Implemented",
    "Remarks",
  ];

  const countryGroup =
    dataList?.codeListElement?.[0]?.codeListVehicleMapping
      ?.flatMap((option) =>
        option.codeListCountryGroupMapping?.map((data) => data.countryGroup)
      ) ?? [];

  const handleCheckbox = (vehicle, countryGroup, isChecked, option) => {
    if (isChecked) {
      setSelectedVehicles((prevState) => [
        ...prevState,
        {
          vehicle,
          countryGroup,
          selectedVehicleGroup: selectedVehicleGroup
            ? selectedVehicleGroup.value
            : null,
          selectedVersion: dataList.version,
          vehicleCountryGroupMappingIdList: [option],
        },
      ]);

      setSelectedOptions((prevState) => {
        const newOption = [...prevState, option];
        return newOption;
      });
    } else {
      setSelectedVehicles(
        selectedVehicles.filter(
          (item) =>
            !(
              item.vehicle === vehicle &&
              item.countryGroup === countryGroup &&
              item.selectedVehicleGroup ===
              (selectedVehicleGroup ? selectedVehicleGroup.value : null) &&
              item.selectedVersion === dataList.version &&
              (item.vehicleCountryGroupMappingIdList
                ? item.vehicleCountryGroupMappingIdList.includes(option)
                : false)
            )
        )
      );

      setSelectedOptions((prevState) =>
        prevState.filter((id) => id !== option)
      );
    }
  };

  useEffect(() => {
    // console.log("selectedVehicles", selectedVehicles);
    console.log("selectedOptions", selectedOptions);
  }, [selectedVehicles, selectedOptions]);


  const handleSubmit = () => {
    props.getSubmitCodeListAction(selectedOptions);
    setGotoCreatorRequest(true)
    setmodal_center(false)
  };

  useEffect(() => {
    if (submitResponse.httpStatusCode === 200 && gotoCreatorRequest) {
      setGotoCreatorRequest(false)

      navigate("/creator-request");
    } else {
      // console.log("u false", submitResponse);
    }

  }, [submitResponse]);

  const downloadExcel = () => {
    const table = document.getElementById("table data"); // Get table by ID

    // Parse HTML table into worksheet
    const worksheet = XLSX.utils.table_to_sheet(table);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook and save the file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Use file-saver to save the file
    saveAs(blob, "Code_Lists_Excel_Report.xlsx");
  };

  const downloadPDF = () => {
    const doc: any = new jsPDF('p', 'pt', 'letter');
    const pageHeight = doc.internal.pageSize.height;

    // Adding a title to the PDF
    doc.text("Code_Lists_PDF_Report", 20, 20);

    // Convert the HTML table to PDF
    doc.autoTable({
      html: "#table\\ data", // Use the HTML table with the specified ID
      startY: 30,
      styles: {
        cellPadding: 2,
        fontSize: 8,
        overflow: 'linebreak',
        columnWidth: 'auto', // Automatically adjust column width
      },
      headStyles: {
        fillColor: [22, 160, 133], // Header background color
        halign: "center", // Center align header text
        lineWidth: 0.1, // Border width for header
        lineColor: [0, 0, 0], // Black border color for header
      },
      bodyStyles: {
        halign: "center", // Center align body text
        lineWidth: 0.1, // Border width for body
        lineColor: [0, 0, 0], // Black border color for body
      },
      theme: "striped",
      // didDrawPage: function (data) {
      //   const footerText = "Generated by Gopi ©2024";
      //   const pageNumber = doc.internal.getNumberOfPages();
      //   doc.setFontSize(10);
      //   doc.text(footerText, data.settings.margin.left, pageHeight - 10);
      //   doc.text(
      //     `Page ${pageNumber}`,
      //     data.settings.margin.left + 160,
      //     pageHeight - 10
      //   );
      // },
    });

    // Save the PDF
    doc.save("Code_Lists_PDF_Report.pdf");
  };



  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Code List Workflow View" pageTitle="Code List" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="card-bg">
                  <h4 className="card-title mb-0 ">WorkFlow View</h4>{" "}
                </CardHeader>

                <CardBody>
                  <div>
                    <div>
                      <Row>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              {/* Vehicle Group */}
                              Vehicle Group{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={selectedVehicleGroup}
                              isMulti={false}
                              onChange={(e) => {
                                handleDropdownChange(e, "VehicleGroupId");
                              }}
                              options={dropDownOptions.vehicleGroup}
                            />
                            <span className="text-validation">
                              {formValidation.VehicleGroupId}
                            </span>
                          </div>
                        </Col>

                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-groups"
                              className="form-label text-muted"
                            >
                              Vehicle Category{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              value={selectedVehicleCategory}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              onChange={(e) => {
                                handleDropdownChange(e, "VehicleCategory");
                              }}
                              options={dropDownOptions.vehicleCategory}
                            />
                            <span className="text-validation">
                              {formValidation.VehicleCategory}
                            </span>
                          </div>
                        </Col>

                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-no-search"
                              className="form-label text-muted"
                            >
                              Tonnage
                            </Label>
                            <Select
                              isClearable={true}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              value={selectedTonnage}
                              onChange={(e) => {
                                handleDropdownChange(e, "Tonnage");
                              }}
                              options={dropDownOptions.tonnage}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Row>
                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-default"
                              className="form-label text-muted"
                            >
                              Vehicle Model
                            </Label>
                            <Select
                              value={selectedVehicleModel}
                              closeMenuOnSelect={false}
                              isMulti={true}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              onChange={(e) => {
                                handleDropdownChange(e, "VehicleModel");
                              }}
                              options={dropDownOptions.vehicalModel}
                            />
                          </div>
                        </Col>

                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-groups"
                              className="form-label text-muted"
                            >
                              Market cluster
                            </Label>
                            <Select
                              value={selectedMarketCluster}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              // searchable
                              onChange={(e) => {
                                handleDropdownChange(e, "MarketCluster");
                              }}
                              options={dropDownOptions.marketCluster}
                            />
                          </div>
                        </Col>

                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-no-search"
                              className="form-label text-muted"
                            >
                              Code Group
                            </Label>
                            <Select
                              isClearable={true}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              value={selectedCodeGroup}
                              onChange={(e) => {
                                handleDropdownChange(e, "CodeGroup");
                              }}
                              options={dropDownOptions.codeGroup}
                            />
                          </div>
                        </Col>

                        <Col lg={4} md={6}>
                          <div className="mb-3">
                            <Label
                              htmlFor="choices-single-no-sorting"
                              className="form-label text-muted"
                            >
                              Code
                            </Label>
                            <Select
                              value={selectedCode}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              styles={{
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: 50,
                                  overflowY: "auto",
                                }),
                              }}
                              onChange={(e) => {
                                handleDropdownChange(e, "Code");
                              }}
                              options={dropDownOptions.code}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <div className="d-flex gap-3 justify-content-md-end ">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Button
                        className="btn btn-primary btn-custom-blue"
                        type="button"
                        color="light"
                        onClick={() => handleClearDropdown()}
                      >
                        Clear
                      </Button>
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                      <Button
                        className="btn btn-primary btn-custom-blue"
                        type="button"
                        color="light"
                        onClick={() => handleViewCodeList()}
                      >
                        Search Code List
                      </Button>
                    </div>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {showTableList && !loading && (
            <Row>
              <Col xl={12}>
                {dataList && dataList.codeListElement === null ? (
                  <Card>
                    <CardHeader className="align-items-center d-flex card-bg">
                      <h4 className="card-title mb-0 flex-grow-1">
                        {codeListLable} Code Lists
                      </h4>
                      <div className="flex-shrink-0">
                        <div className="form-check form-switch form-switch-right form-switch-md">
                          <div className="input-group gap-3 justify-content-md-end ">
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                              <Button
                                className="btn btn-primary btn-custom-blue"
                                type="button"
                                color="light"
                              >
                                Export Excel
                              </Button>
                            </div>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                              <Button
                                className="btn btn-primary btn-custom-blue"
                                type="button"
                                color="light"
                              >
                                Export PDF
                              </Button>
                            </div>
                            {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                              <Button
                                className="btn btn-primary btn-custom-blue"
                                type="button"
                                color="light"
                                style={{
                                  display:
                                    selectedVehicles.length > 0 ? "" : "none",
                                }}
                                onClick={() => tog_center()}
                              >
                                Submit
                              </Button>
                            </div> */}
                            {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                      <Button className="btn btn-primary btn-custom-blue" type="button" color='light' >Edit</Button>
                                  </div> */}
                          </div>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <table className="table align-middle table-bordered  table-striped-columns table-nowrap border-dark table-striped mb-0">
                        <thead>
                          <tr>
                            <th className="border-dark">Code Group</th>
                            <th className="border-dark">Code</th>
                            <th className="border-dark">Code Description</th>
                            <th className="border-dark">Notes</th>
                            <th className="border-dark">Is Implemented</th>
                            <th className="border-dark">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan={6} className="text-center">
                              No Data Found
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </CardBody>
                  </Card>
                ) : (
                  <Card>
                    {/* <PreviewCardHeader title="View Code Lists" value ={true} /> */}
                    <CardHeader className="align-items-center d-flex card-bg">
                      <h4 className="card-title mb-0 flex-grow-1">
                        {codeListLable} Code Lists WorkFlow
                      </h4>
                      <div className="flex-shrink-0">
                        <div className="form-check form-switch form-switch-right form-switch-md">
                          <div className="input-group gap-3 justify-content-md-end ">
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                              <Button
                                className="btn btn-primary btn-custom-blue"
                                type="button"
                                color="light"
                                onClick={() => downloadExcel()}
                              >
                                Export Excel
                              </Button>
                            </div>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                              <Button
                                className="btn btn-primary btn-custom-blue"
                                type="button"
                                color="light"
                                onClick={() => downloadPDF()}
                              >
                                Export PDF
                              </Button>
                            </div>
                            {/* {Role && Role?.createAccess &&
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                              <Button
                                className="btn btn-primary btn-custom-blue"
                                type="button"
                                color="light"
                                style={{
                                  display:
                                    selectedVehicles.length > 0 ? "" : "none",
                                }}
                                onClick={() => tog_center()}
                              >
                                Submit
                              </Button>
                            </div>} */}
                            {/* <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <Button className="btn btn-primary btn-custom-blue" type="button" color='light' >Edit</Button>
                                    </div> */}
                          </div>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="card-padding">
                      <div className="live-preview">
                        <div
                          className="table-responsive table-card"
                          style={{ maxHeight: "700px" }}
                        >
                          <table
                            id="table data"
                            className="table align-middle table-bordered  table-striped-columns table-nowrap border-dark table-striped mb-0"
                          >
                            <thead
                              className="table-light"
                              style={{
                                position: "sticky",
                                top: "0",
                                zIndex: "1",
                              }}
                            >
                              <tr>
                                {staticHeader.map((data, index) => (
                                  <td
                                    key={index}
                                    rowSpan={4}
                                    className="center"
                                  >
                                    {data}
                                  </td>
                                ))}
                                {dataList?.codeListElement[0]?.codeListVehicleMapping?.map(
                                  (option, index) => (
                                    <td
                                      key={index}
                                      colSpan={
                                        option?.codeListCountryGroupMapping
                                          ?.length
                                      }
                                      className="center"
                                    >
                                      {option.vehicleName}
                                    </td>
                                  )
                                )}
                              </tr>

                              <tr>
                                {countryGroup?.map((data, index) =>
                                  data instanceof Array ? (
                                    data.map((option, index) => (
                                      <td key={index} className="center">
                                        {option}
                                      </td>
                                    ))
                                  ) : (
                                    <td key={index} className="center">
                                      {data}
                                    </td>
                                  )
                                )}
                              </tr>
                              <tr>
                                {countryGroup?.map((data, index) =>

                                  <td key={index} className="center">
                                    {dataList.version}
                                  </td>

                                )}
                              </tr>
                              {/* <tr>
                                {dataList?.codeListElement[0]?.codeListVehicleMapping?.map(
                                  (vehicle, vehicleIndex) =>
                                    vehicle.codeListCountryGroupMapping.map(
                                      (countryGroup, countryIndex) => (
                                        <td
                                          key={countryIndex}
                                          className="center"
                                        >
                                          <input
                                            type="checkbox"
                                            onChange={(e) =>
                                              handleCheckbox(
                                                vehicle.vehicleName,
                                                countryGroup.countryGroup,
                                                e.target.checked,
                                                countryGroup.vehicleCountryGroupId
                                              )
                                            }
                                          />
                                        </td>
                                      )
                                    )
                                )}
                              </tr> */}
                            </thead>
                            <tbody>
                              {dataList?.codeListElement?.map((data, index) => (
                                <tr key={index}>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.codeGroup}
                                  </td>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.code}
                                  </td>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.codeDescription}
                                  </td>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.notes}
                                  </td>
                                  <td className="center">
                                    {data.isImplemented}
                                  </td>
                                  <td
                                    className="center"
                                    style={{ whiteSpace: "normal" }}
                                  >
                                    {data.remarks}
                                  </td>
                                  {data?.codeListVehicleMapping?.map(
                                    (option, index) =>
                                      option.codeListCountryGroupMapping.map(
                                        (output, indx) => (
                                          <td key={indx} className="center" style={output.isUpdatedOnPreviousVersion ? {backgroundColor: "#fafa0070"} : {}}>
                                            {output.optionValue}
                                          </td>
                                        )
                                      )
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="d-none code-view">
                        <pre
                          className="language-markup"
                          style={{ height: "275px" }}
                        >
                          <code>{/* <Striped /> */}</code>
                        </pre>
                      </div>
                    </CardBody>
                  </Card>
                )}
              </Col>
            </Row>
          )}
        </Container>
      </div>

      {/* <Modal
        isOpen={modal_center}
        toggle={() => {
          tog_center();
        }}
        centered
      >
        <ModalHeader className="modal-title" />

        <ModalBody className="text-center p-5">
          <div className="">
            <h4 className="mb-3">
              Are you sure you want to draft the following request?
            </h4>
            <div
              style={{ maxHeight: "200px", overflowY: "scroll" }}
              className="list-group list-group-flush"
            >
              {selectedVehicles.map((item, index) => (
                <li className="list-group-item" key={index}>
                  <b>Vehicle:</b> {item.vehicle} <br />
                  <b>Country Group:</b> {item.countryGroup} <br />
                  <b>Vehicle Group:</b> {item.selectedVehicleGroup} <br />
                  <b>Version:</b> {item.selectedVersion}
                </li>
              ))}
            </div>
            <div className="hstack gap-5 mt-5 justify-content-center">
              <Button color="light" onClick={() => setmodal_center(false)}>
                Close
              </Button>
              <Link to="#" className="btn btn-success" onClick={handleSubmit}>
                Submit
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal> */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  dropdownCodeList: state.CodeListReducer.dropdownCodeList,
  viewTableData: state.CodeListReducer.viewTableData,
  loading: state.CodeListReducer.loading,
  submitResponse: state.CodeListReducer.submitResponse,
  CodeWorkflowTrackerTableData:state.CodeListReducer.CodeWorkflowTrackerTableData
});

const mapDispatchToProps = (dispatch) => ({
  getDropdownCodeListAction: (data) => {
    dispatch(getDropdownCodeListAction(data));
  },
  // getViewCodeListTableAction: (data) => {
  //   dispatch(getViewCodeListTableAction(data));
  // },
  getCodeWorkflowTrackerTableRequest:(data) =>{
    dispatch(getCodeWorkflowTrackerTableRequest(data));
  },

  getSubmitCodeListAction: (data) => {
    dispatch(getSubmitCodeListAction(data));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeWorkflowTracker);
